<template>
  <Transitions>
    <InputHelperText v-if="errorMessageInternal" v-bind="$attrs" :id="id" data-test-id="form-error" variant="error">
      {{ errorMessageInternal }}
    </InputHelperText>
  </Transitions>
</template>

<script lang="ts" setup>
import { computed, toRef } from 'vue'
import { useField } from 'vee-validate'

import { useFormEvents } from '@lasso/shared/hooks'

import InputHelperText from '../Input/InputHelperText.vue'
import Transitions from '../Transitions/Transitions.vue'

const props = withDefaults(defineProps<{
  id?: string
  errorText?: string
}>(), {
  id: '',
  errorText: '',
})

defineOptions({ inheritAttrs: false })

const field = props.id
  ? useField(toRef(props, 'id'))
  : null

const errorMessageInternal = computed(() => field?.errorMessage?.value || props.errorText || '')

const formEvents = useFormEvents()

formEvents.validationError.on(() => {
  if (errorMessageInternal.value) {
    formEvents.fieldError.trigger()
  }
})
</script>
