<template>
  <Box flex wrap="wrap">
    <Box v-for="panelNumber in panels" :key="panelNumber" alignItems="center">
      <Panel
        v-bind="$attrs"
        :month="getMonthForPanel(panelNumber)"
        :datePickerMode="mode"
        :singleDate="singleDateValue"
        :periodDates="periodDatesValue"
        :multipleDates="multipleDatesValue"
        :periodPosition="activePeriodPosition"
        :hideForward="panelNumber < panels"
        :hideBack="panelNumber > 1"
        :max="max"
        :min="min"
        :disabled="disabled"
        :disabledPeriods="disabledPeriods"
        :disabledWeekdays="disabledWeekdays"
        :noPadding="noPadding"
        :hideToday="hideToday"
        :locale="locale"
        :dateFormat="dateFormat"
        :firstDayOfTheWeek="currentFirstDayOfTheWeek"
        :timezone="timezone"
        @navigateMonth="navigateMonth"
        @update:singleDate="handleSingleDateUpdate"
        @update:periodDates="handlePeriodDatesUpdate"
        @update:multipleDates="handleMultipleDatesUpdate"
        @setMonth="handleSetMonth"
        @setYear="handleSetYear"
      >
        <template #bottom>
          <slot name="bottom" />
        </template>
      </Panel>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { computed, shallowRef, watch } from 'vue'
import { DateTime, Info } from 'luxon'
import { TimeZone } from '@lasso/shared/consts'

import Box from '../Box/Box.vue'

import { isShortDateFormat as _isShortDateFormat, isInRange, isLowerBound, isUpperBound } from '../InputDate/utils'

import { useTimezoneConsistencyCheck } from '../../hooks/useTimezoneConsistencyCheck'

import Panel from './Panel/Panel.vue'

import type { DateFormatTypes, DatePickerMode, DisabledPeriod, MultipleDatesValue, PeriodDateValue, PeriodPosition, SingleDateValue } from './types'

const props = withDefaults(defineProps<{
  mode: DatePickerMode
  singleDate?: DateTime | null
  periodDates?: PeriodDateValue
  multipleDates?: DateTime[]
  panels?: number
  locale?: string
  dateFormat: DateFormatTypes
  noPadding?: boolean
  hideToday?: boolean
  disabled?: boolean
  // Weekday number in ISO 8601 where 1 is Monday and 7 is Sunday regardless of locale — https://en.wikipedia.org/wiki/ISO_week_date
  disabledWeekdays?: number[]
  initialMonth?: DateTime | null
  min?: DateTime
  max?: DateTime
  disabledPeriods?: DisabledPeriod[]
  activePeriodPosition?: PeriodPosition
  timezone?: TimeZone
  // Internal prop to skip timezone consistency check which is only relevant in development mode
  skipDevOnlyTimezoneCheck?: boolean
  firstDayOfTheWeek?: number
}>(), {
  panels: 1,
  locale: 'en-US',
  dateFormat: 'MM/dd/yyyy',
  activePeriodPosition: 'start',
  noPadding: false,
  hideToday: false,
  disabledWeekdays: () => [],
  disabledPeriods: () => [],
  timezone: TimeZone.UTC,
  singleDate: null,
  periodDates: () => ({ start: null, end: null }),
  multipleDates: () => [],
  skipDevOnlyTimezoneCheck: false,
})

const emits = defineEmits<{
  'update:singleDate': [DateTime | null]
  'update:periodDates': [PeriodDateValue]
  'update:multipleDates': [DateTime[]]
}>()

defineOptions({
  inheritAttrs: false,
})

const month = shallowRef<DateTime>(
  props.initialMonth
    ? props.initialMonth
    : DateTime.local().setZone(props.timezone).setLocale(props.locale).startOf('month'),
)

const singleDateValue = computed((): SingleDateValue => {
  if (props.mode === 'single') {
    return props.singleDate
  }
  return null
})

const periodDatesValue = computed((): PeriodDateValue => {
  if (props.mode === 'period') {
    return props.periodDates
  }
  return { start: null, end: null }
})

const multipleDatesValue = computed((): MultipleDatesValue =>
  props.mode === 'multiple' ? props.multipleDates : [],
)

const currentFirstDayOfTheWeek = computed(() => {
  return props.firstDayOfTheWeek ? props.firstDayOfTheWeek : Info.getStartOfWeek({ locale: props.locale })
})

const isShortDateFormat = computed(() => _isShortDateFormat(props.dateFormat))

const normalizeMonthDateWithBounds = (month: DateTime) => {
  if (props.min && isLowerBound(month, props.min, props.dateFormat)) {
    return props.min
  }
  if (props.max && isUpperBound(month, props.max, props.dateFormat)) {
    return props.max
  }
  return month
}

const setDate = (date: DateTime) => {
  if (!isInRange(date, props.min, props.max)) {
    return
  }

  if (props.mode === 'single') {
    emits('update:singleDate', date)
  }
  else if (props.mode === 'multiple') {
    const exists = props.multipleDates.find((d: DateTime) => d.hasSame(date, 'day'))
    if (exists) {
      emits('update:multipleDates', props.multipleDates.filter((d: DateTime) => !d.hasSame(date, 'day')))
    }
    else {
      emits('update:multipleDates', [...props.multipleDates, date])
    }
  }
  else if (props.mode === 'period') {
    let dates: PeriodDateValue = { start: null, end: null }

    if (props.activePeriodPosition) {
      if (props.activePeriodPosition === 'start') {
        dates = { start: date, end: props.periodDates.end }
      }
      else {
        dates = { start: props.periodDates.start, end: date }
      }
    }
    else {
      if (!props.periodDates.start) {
        dates = { start: date, end: null }
      }
      else if (!props.periodDates.end && !date.hasSame(props.periodDates.start, 'day')) {
        dates = { start: props.periodDates.start, end: date }
      }
      else {
        dates = { start: date, end: null }
      }
    }

    if (isShortDateFormat.value && dates.start && dates.end && props.max && !isUpperBound(dates.end, props.max, props.dateFormat)) {
      dates.end = dates.end.endOf('month')
    }

    emits('update:periodDates', dates)
  }
}

const navigateMonth = (offset: number) => {
  month.value = month.value.plus({ months: offset })
  if (isShortDateFormat.value) {
    setDate(normalizeMonthDateWithBounds(month.value))
  }
}

const handleSingleDateUpdate = (date: SingleDateValue) => {
  if (date && !isInRange(date, props.min, props.max)) return
  emits('update:singleDate', date)
}

const handlePeriodDatesUpdate = (dates: PeriodDateValue) => {
  emits('update:periodDates', dates)
}

const handleMultipleDatesUpdate = (dates: MultipleDatesValue) => {
  emits('update:multipleDates', dates)
}

const handleSetMonth = (newMonth: DateTime) => {
  month.value = newMonth
  if (isShortDateFormat.value) {
    setDate(normalizeMonthDateWithBounds(month.value))
  }
}

const handleSetYear = (year: DateTime) => {
  month.value = year
  if (isShortDateFormat.value) {
    setDate(normalizeMonthDateWithBounds(month.value))
  }
}

const getMonthForPanel = (panelNumber: number): DateTime => {
  if (panelNumber === 1) {
    return month.value
  }

  return month.value.plus({ months: panelNumber - 1 })
}

watch(() => props.initialMonth,
  () => {
    if (props.initialMonth) {
      month.value = props.initialMonth
    }
  }, {
    immediate: true,
  })

watch(() => props.singleDate,
  (newDate) => {
    if (props.mode === 'single' && newDate) {
      month.value = newDate.startOf('month')
    }
  }, { immediate: true })

watch(() => props.periodDates,
  (newDates) => {
    if (props.mode === 'period') {
      if (props.activePeriodPosition === 'start' && newDates.start) {
        month.value = newDates.start.startOf('month')
      }
      else if (props.activePeriodPosition === 'end' && newDates.end) {
        month.value = newDates.end.startOf('month')
      }
      else if (newDates.start) {
        month.value = newDates.start.startOf('month')
      }
    }
  }, { immediate: true })

watch(() => props.multipleDates,
  (newDates) => {
    if (props.mode === 'multiple' && newDates?.length > 0) {
      const lastDate = newDates[newDates.length - 1]
      if (lastDate) {
        month.value = lastDate.startOf('month')
      }
    }
  }, { immediate: true })

useTimezoneConsistencyCheck({
  timezone: () => props.timezone,
  datesToValidate: () => ({
    singleDate: props.singleDate,
    periodDates: props.periodDates,
    multipleDates: props.multipleDates,
    min: props.min,
    max: props.max,
    disabledPeriods: props.disabledPeriods,
    initialMonth: props.initialMonth,
  }),
  skip: props.skipDevOnlyTimezoneCheck,
})
</script>
