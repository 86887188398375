import { Ref, computed } from 'vue'

// Temporary fix for empty value handling in select
export const useSelectValue = <E extends null | undefined, V extends string | number | E>(
  value: Ref<V>,
  emptyValue: E,
): Ref<Exclude<V, E> | ''> => {
  return computed({
    get: () => {
      return (!value.value || value.value === emptyValue) ? '' : value.value as Exclude<V, E>
    },
    set: (newValue) => {
      value.value = (newValue || emptyValue) as V
    },
  })
}
