<template>
  <Draggable
    data-test-id="draggable-nested-draggable"
    :modelValue="items as any[]"
    v-bind="draggableProps"
    :emptyInsertThreshold="emptyInsertThreshold"
    :group="{ name: group, put: onPut }"
    @change="handleChange($event as any)"
  >
    <Component
      :is="itemComponent"
      v-for="(id, index) in items"
      :key="id"
      v-bind="{ ...itemProps, ...getItemProps(id, index as number) }"
      @removeItem="handleRemoveItem(id)"
      @update:item="handleUpdateItem(id, $event)"
      @update:parentItem="handleUpdateItem(itemIdInternal, $event)"
    >
      <DraggableNestedBase
        :itemId="id"
        :itemComponent="itemComponent"
        :itemProps="itemProps"
        :group="group"
        :maxDepth="maxDepth"
        :draggableProps="draggableProps"
        :depth="depth + 1"
        :convertItem="convertItem"
        :addItem="convertAddItem"
        :emptyInsertThreshold="emptyInsertThreshold"
      />
    </Component>
  </Draggable>
</template>

<script setup lang="ts" generic="T extends { items?: T[]; }">
import { computed } from 'vue'

import { NormalizedItem, NormalizedItemId } from '@lasso/shared/hooks'

import { Draggable, DraggableChangeEvent, DraggableGroupsOptionsEvent } from '../Draggable'

import { useDraggableNestedState } from './useDraggableNestedState'
import { DraggableNestedBaseProps, DraggableNestedItemProps } from './types'

const {
  itemComponent,
  itemId,
  group,
  convertItem,
  addItem: convertAddItem = item => item as any,
  draggableProps,
  emptyInsertThreshold,
  itemProps,
  depth = 0,
  maxDepth = Infinity,
} = defineProps<DraggableNestedBaseProps<T>>()

const {
  rootId,
  getItem,
  getItemParentId,
  updateItemChildren,
  deleteItem,
  addItem,
  moveItem,
  updateItem,
  getMaxDepth,
} = useDraggableNestedState<T>()!

const itemIdInternal = computed(() => itemId ?? rootId.value!)
const item = computed(() => getItem(itemIdInternal.value))
const items = computed(() => (item.value?.items ?? []).filter(id => getItem(id)))
const parentItemId = computed(() => getItemParentId(itemIdInternal.value))
const parentItem = computed<NormalizedItem<T> | null>(() => parentItemId.value ? getItem(parentItemId.value) : null)

const getItemProps = (id: NormalizedItemId, index: number): DraggableNestedItemProps<any> => {
  return {
    item: getItem(id)!,
    parentItem: getItem(itemIdInternal.value)!,
    index,
    isFirst: index === 0,
    isLast: index === items.value.length - 1,
    depth,
  }
}

const handleChange = (event: DraggableChangeEvent<NormalizedItemId>) => {
  if (!item.value) {
    return
  }

  if ('added' in event) {
    const addedItem = event.added.element
    // Handle items added from another list
    if (addedItem && typeof itemIdInternal.value !== typeof addedItem && typeof addedItem === 'object') {
      event.added.element = addItem({ ...convertAddItem(addedItem), items: [] })
    }

    const convertedItem = convertItem?.(item.value)

    // A new parent item was created for the added item
    if (convertedItem && parentItem.value) {
      const index = parentItem.value.items.indexOf(itemIdInternal.value)
      const newItemId = addItem(
        { ...(convertedItem as NormalizedItem<T>), items: [event.added.element] },
        parentItemId.value!,
        index,
      )

      moveItem(itemIdInternal.value, newItemId, 0)
    }
    // Add item - it was removed from the other list already
    else {
      const items = item.value.items.slice()
      items.splice(event.added.newIndex, 0, event.added.element)

      updateItemChildren(itemIdInternal.value, items)
    }
  }
  else if ('removed' in event) {
    const newItems = item.value.items.filter(otherId => otherId !== event.removed.element)

    // Lift the item in place of its parent because it is the last in its parent
    if (newItems.length === 1 && parentItem.value) {
      const newParentItems = parentItem.value.items.map(id => id === itemIdInternal.value ? newItems[0]! : id)
      updateItemChildren(parentItemId.value!, newParentItems)
    }
    else {
      updateItemChildren(itemIdInternal.value, newItems)
    }
  }
  // Move item in the same array
  else if ('moved' in event) {
    moveItem(event.moved.element, itemIdInternal.value, event.moved.newIndex)
  }
}

const handleRemoveItem = (id: NormalizedItemId) => {
  if (!item.value) {
    return
  }

  const updatedItems = item.value.items.filter(otherId => otherId !== id)

  // Delete the whole parent if it doesn't have items
  if (updatedItems.length === 0) {
    deleteItem(itemIdInternal.value)
  }
  // Lift the item in place of its parent because it is the last in its parent
  else if (updatedItems.length === 1 && parentItem.value) {
    const newParentItems = parentItem.value.items.map(id => id === itemIdInternal.value ? updatedItems[0]! : id)
    updateItemChildren(parentItemId.value!, newParentItems)
  }

  deleteItem(id)
}

const handleUpdateItem = (id: NormalizedItemId, data: Exclude<T, 'items'>) => {
  updateItem(id, data)
}

const onPut = ({ fromItem: fromId }: DraggableGroupsOptionsEvent) => {
  const isFromOwnState = Boolean(getItem(fromId as NormalizedItemId))
  const _maxDepth = isFromOwnState ? getMaxDepth(fromId as NormalizedItemId) : 0

  return depth <= maxDepth - _maxDepth
}
</script>
