<template>
  <DropdownList>
    <slot>
      <Button size="lg" variant="outlined">
        <slot>
          Columns
        </slot>
      </Button>
    </slot>
    <template #dropdown>
      <Draggable v-model="orderInternal">
        <Box
          v-for="field in fieldsOrdered"
          v-show="!hidden.includes(field.id)"
          :key="field.id"
          component="label"
          flex
          wrap="nowrap"
          alignItems="center"
          width="100%"
          minHeight="36px"
          py="1.5"
          px="4"
          spaceX="2"
          class="hover:bg-base-500 cursor-pointer"
        >
          <Checkbox v-model="enabledInternal" :value="field.id" :disabled="isDisabled(field.id)" />
          <Typography variant="body2" color="textPrimary">
            {{ field.label }}
          </Typography>
          <Box ml="auto" alignItems="center">
            <Icon
              icon="drag_indicator"
              class="cursor-move"
              data-role="handle"
            />
          </Box>
        </Box>
      </Draggable>
    </template>
  </DropdownList>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import { useVModel } from '@vueuse/core'

import { Ref, computed } from 'vue'

import { truthy } from '@lasso/shared/utils'

import { uniq } from 'lodash-es'

import { TableColumns } from '../Table/types'

import Box from '../Box/Box.vue'
import Button from '../Button/Button.vue'
import Checkbox from '../Checkbox/Checkbox.vue'
import Draggable from '../Draggable/Draggable.vue'
import DropdownList from '../DropdownList/DropdownList.vue'
import Icon from '../Icon/Icon.vue'
import Typography from '../Typography/Typography.vue'
import { VirtualTableColumns } from '../VirtualTable'

const props = withDefaults(defineProps<{
  enabled: Array<keyof T>
  order: Array<keyof T>
  required?: Array<keyof T>
  hidden?: Array<keyof T>
  fields: TableColumns<T> | VirtualTableColumns<T>
}>(), {
  required: () => [],
  hidden: () => [],
})

const emit = defineEmits<{
  'update:enabled': [Array<keyof T>]
  'update:order': [Array<keyof T>]
}>()

const missingFields = computed(() => {
  return props.fields.filter(field => !props.order.includes(field.id)).map(field => field.id)
})

const enabledInternal = useVModel(props, 'enabled', emit) as Ref<Array<keyof T>>

const fieldsOrdered = computed(() => {
  return uniq([...props.order, ...missingFields.value])
    .map(id => props.fields.find(field => field.id === id))
    .filter(truthy)
})

const orderInternal = computed({
  get: () => {
    return fieldsOrdered.value.map(field => field.id)
  },
  set: (value) => {
    emit('update:order', value)
  },
})

const isDisabled = (id: keyof T) => {
  return props.required.includes(id) && enabledInternal.value.includes(id)
}
</script>
