<template>
  <Grid container xs="7" gapY="0.5" @mouseleave="handleRootMouseLeave">
    <Grid
      v-for="(calendarDay, index) in allDays"
      :key="index"
      width="auto"
      item
      class="day-cell"
      :class="daysClasses(calendarDay)"
      :data-test-id="isSelected(calendarDay) ? `selected-${calendarDay.date.day}` : calendarDay.date.day"
    >
      <ButtonIcon
        v-if="!isHiddenDay(calendarDay)"
        class="day"
        :variant="buttonVariant(calendarDay)"
        :color="buttonColor(calendarDay)"
        size="lg"
        shape="circle"
        :disabled="isDisabled(calendarDay)"
        :class="buttonClasses(calendarDay)"
        data-test-id="today-day-button"
        @click="handleDayClick(calendarDay)"
        @mouseover.self="handleDayHover(calendarDay, isDisabled(calendarDay))"
      >
        <Typography variant="body2" :color="typographyColor(calendarDay)">
          {{ calendarDay.date.toFormat(dateFormat) }}
        </Typography>
      </ButtonIcon>
    </Grid>
  </Grid>
</template>

<script setup lang="ts">
import { DateTime, Info } from 'luxon'
import { computed, ref, watch } from 'vue'
import { range } from 'lodash-es'
import { useVModel } from '@vueuse/core'
import { TimeZone } from '@lasso/shared/consts'

import ButtonIcon from '../../../ButtonIcon/ButtonIcon.vue'
import Grid from '../../../Grid/Grid.vue'
import Typography from '../../../Typography/Typography.vue'
import { CalendarDay, DatePickerMode, DisabledPeriod, MonthRelation, MultipleDatesValue, PeriodDateValue, PeriodPosition, SingleDateValue } from '../../types'

const props = withDefaults(
  defineProps<{
    datePickerMode: DatePickerMode
    singleDate?: SingleDateValue
    periodDates?: PeriodDateValue
    multipleDates?: MultipleDatesValue
    periodPosition?: PeriodPosition
    month: DateTime
    dateFormat?: string
    timezone: TimeZone
    firstDayOfTheWeek?: number
    min?: DateTime
    max?: DateTime
    disabled?: boolean
    disabledPeriods?: DisabledPeriod[]
    disabledWeekdays?: number[]
    disabledDays?: DateTime[]
    showPrevMonth?: boolean
    showNextMonth?: boolean
    locale: string
  }>(),
  {
    singleDate: null,
    periodPosition: 'start',
    periodDates: () => ({ start: null, end: null }),
    multipleDates: () => [],
    dateFormat: 'd',
    disabledPeriods: () => [],
    disabledWeekdays: () => [],
    disabledDays: () => [],
    showPrevMonth: false,
    showNextMonth: false,
  },
)

const emits = defineEmits<{
  'update:singleDate': [SingleDateValue]
  'update:periodDates': [PeriodDateValue]
  'update:multipleDates': [MultipleDatesValue]
}>()

const singleDateInternal = useVModel(props, 'singleDate', emits)
const periodDatesInternal = useVModel(props, 'periodDates', emits)
const multipleDatesInternal = useVModel(props, 'multipleDates', emits)

const periodPreviewState = ref<PeriodDateValue>({ start: null, end: null })

// Reset state when datePickerMode changes as previous state is not valid for new mode
watch(() => props.datePickerMode, () => {
  singleDateInternal.value = null
  periodDatesInternal.value = { start: null, end: null }
  multipleDatesInternal.value = []
  periodPreviewState.value = { start: null, end: null }
})

const handleDayClick = (day: CalendarDay) => {
  if (props.datePickerMode === 'single') {
    singleDateInternal.value = day.date
  }
  else if (props.datePickerMode === 'period') {
    if (props.periodPosition === 'start') {
      periodDatesInternal.value = {
        ...periodDatesInternal.value,
        start: day.date,
      }
    }
    else {
      periodDatesInternal.value = {
        ...periodDatesInternal.value,
        end: day.date,
      }
    }
  }
  else if (props.datePickerMode === 'multiple') {
    const exists = multipleDatesInternal.value.find((d: DateTime) => d.hasSame(day.date, 'day'))
    if (exists) {
      multipleDatesInternal.value = multipleDatesInternal.value.filter((d: DateTime) => !d.hasSame(day.date, 'day'))
    }
    else {
      multipleDatesInternal.value = [...multipleDatesInternal.value, day.date]
    }
  }

  periodPreviewState.value = { start: null, end: null }
}

const handleDayHover = (day: CalendarDay, isDisabled: boolean) => {
  if (isDisabled) {
    periodPreviewState.value = { start: null, end: null }
    return
  }

  if (props.datePickerMode === 'period') {
    const hasFullPeriod = periodDatesInternal.value.start !== null && periodDatesInternal.value.end !== null

    // If both dates are selected - do not show any preview
    if (hasFullPeriod) {
      periodPreviewState.value = { start: null, end: null }
      return
    }

    // Do not show preview if hovering over the opposite boundary
    if (
      (props.periodPosition === 'start' && periodDatesInternal.value.end?.hasSame(day.date, 'day'))
      || (props.periodPosition === 'end' && periodDatesInternal.value.start?.hasSame(day.date, 'day'))
    ) {
      periodPreviewState.value = { start: null, end: null }
      return
    }

    // Show preview only when selecting the second date
    if (props.periodPosition === 'start') {
      periodPreviewState.value = {
        start: day.date,
        end: periodDatesInternal.value.end,
      }
    }
    else {
      periodPreviewState.value = {
        start: periodDatesInternal.value.start,
        end: day.date,
      }
    }
  }
  else {
    periodPreviewState.value = { start: null, end: null }
  }
}

const handleRootMouseLeave = () => {
  periodPreviewState.value = { start: null, end: null }
}

const isDisabled = (day: CalendarDay) => {
  if (props.disabled) {
    return true
  }
  if (props.disabledWeekdays.includes(day.date.weekday)) {
    return true
  }
  if (props.min && day.date < props.min.startOf('day')) {
    return true
  }
  if (props.max && day.date > props.max.endOf('day')) {
    return true
  }
  if (props.disabledDays.find(d => day.date.hasSame(d, 'day'))) {
    return true
  }
  if (
    props.disabledPeriods?.some(
      period => day.date >= period.start && day.date <= period.end,
    )
  ) {
    return true
  }

  return false
}

const isHiddenDay = (calendarDay: CalendarDay) => {
  return (
    (calendarDay.monthRelation === MonthRelation.PREVIOUS_MONTH && !props.showPrevMonth) || (calendarDay.monthRelation === MonthRelation.NEXT_MONTH && !props.showNextMonth)
  )
}

const isCurrentMonthDay = (calendarDay: CalendarDay) => {
  return calendarDay.monthRelation === MonthRelation.CURRENT_MONTH
}

const isInPeriod = (calendarDay: CalendarDay, period: PeriodDateValue) => {
  if (!period.start || !period.end) {
    return false
  }
  return calendarDay.date > period.start.endOf('day') && calendarDay.date < period.end.startOf('day')
}

const isPeriodStart = (calendarDay: CalendarDay, period: PeriodDateValue) => {
  return period.start?.hasSame(calendarDay.date, 'day') ?? false
}

const isPeriodEnd = (calendarDay: CalendarDay, period: PeriodDateValue) => {
  return period.end?.hasSame(calendarDay.date, 'day') ?? false
}

const isToday = (calendarDay: CalendarDay) => {
  return calendarDay.date.hasSame(DateTime.now().setZone(props.timezone), 'day')
}

const isSelected = (calendarDay: CalendarDay) => {
  if (props.datePickerMode === 'single') {
    return singleDateInternal.value?.hasSame(calendarDay.date, 'day') ?? false
  }
  else if (props.datePickerMode === 'period') {
    return periodDatesInternal.value.start?.hasSame(calendarDay.date, 'day')
      || periodDatesInternal.value.end?.hasSame(calendarDay.date, 'day')
      || false
  }
  else {
    return multipleDatesInternal.value.some(d => d.hasSame(calendarDay.date, 'day'))
  }
}

const daysClasses = (calendarDay: CalendarDay) => {
  const hasFullPeriod = periodDatesInternal.value.start !== null && periodDatesInternal.value.end !== null
  const hasFullPreview = periodPreviewState.value.start !== null && periodPreviewState.value.end !== null
  const isSameDayPeriod = hasFullPeriod && periodDatesInternal.value.start?.hasSame(periodDatesInternal.value.end!, 'day')

  return {
    'hidden-day': isHiddenDay(calendarDay),
    'current-month-day': isCurrentMonthDay(calendarDay),
    'today': isToday(calendarDay),
    'period': props.datePickerMode === 'period' && (
      (hasFullPeriod && !isSameDayPeriod && isInPeriod(calendarDay, periodDatesInternal.value))
      || (!hasFullPeriod && isInPeriod(calendarDay, periodPreviewState.value))
    ),
    'selected': isSelected(calendarDay),
    'period-start': props.datePickerMode === 'period' && (
      (hasFullPeriod && !isSameDayPeriod && isPeriodStart(calendarDay, periodDatesInternal.value))
      || (!hasFullPeriod && hasFullPreview && isPeriodStart(calendarDay, periodPreviewState.value))
    ),
    'period-end': props.datePickerMode === 'period' && (
      (hasFullPeriod && !isSameDayPeriod && isPeriodEnd(calendarDay, periodDatesInternal.value))
      || (!hasFullPeriod && hasFullPreview && isPeriodEnd(calendarDay, periodPreviewState.value))
    ),
  }
}

const buttonVariant = (calendarDay: CalendarDay) => {
  const hasFullPeriod = periodDatesInternal.value.start !== null && periodDatesInternal.value.end !== null
  const hasFullPreview = periodPreviewState.value.start !== null && periodPreviewState.value.end !== null

  if (
    isSelected(calendarDay)
    || (props.datePickerMode === 'period' && !hasFullPeriod && hasFullPreview && (isPeriodStart(calendarDay, periodPreviewState.value) || isPeriodEnd(calendarDay, periodPreviewState.value)))
  ) {
    return 'default'
  }
  else if (isToday(calendarDay)) {
    return 'outlined'
  }
  return 'ghost'
}

const buttonColor = (calendarDay: CalendarDay) => {
  const hasFullPeriod = periodDatesInternal.value.start !== null && periodDatesInternal.value.end !== null
  const hasFullPreview = periodPreviewState.value.start !== null && periodPreviewState.value.end !== null

  if (
    isSelected(calendarDay)
    || (props.datePickerMode === 'period' && !hasFullPeriod && hasFullPreview && (isPeriodStart(calendarDay, periodPreviewState.value) || isPeriodEnd(calendarDay, periodPreviewState.value)))
    || isToday(calendarDay)
  ) {
    return 'primary'
  }
  return 'default'
}

const typographyColor = (calendarDay: CalendarDay) => {
  const hasFullPeriod = periodDatesInternal.value.start !== null && periodDatesInternal.value.end !== null
  const hasFullPreview = periodPreviewState.value.start !== null && periodPreviewState.value.end !== null

  if (
    isSelected(calendarDay)
    || (props.datePickerMode === 'period' && !hasFullPeriod && hasFullPreview && (isPeriodStart(calendarDay, periodPreviewState.value) || isPeriodEnd(calendarDay, periodPreviewState.value)))
  ) {
    return 'inherit'
  }
  else if (isDisabled(calendarDay)) {
    return 'disabled'
  }
  return 'textPrimary'
}

/**
 * The date picker uses Tippy's interactive mode to keep focus on the input when clicking dates.
 * Disabled buttons don't work well with this - they don't properly handle focus/click events.
 * To fix this, we make clicks pass through disabled dates to the picker container below,
 * which properly handles the interaction and keeps the picker open.
 */
const buttonClasses = (day: CalendarDay) => ({
  'pointer-events-none': isDisabled(day),
})

const previousMonthDays = computed((): CalendarDay[] => {
  const monthFirstDay = props.month.set({ day: 1 })
  const firstDayOfTheWeek = props.firstDayOfTheWeek ?? Info.getStartOfWeek({ locale: props.locale })

  const diff = monthFirstDay.weekday - firstDayOfTheWeek
  const daysToAdd = diff < 0 ? diff + 7 : diff

  const days = range(0, daysToAdd)
    .map(index => monthFirstDay.minus({ days: index + 1 }))
    .reverse()

  return days.map(date => ({ date, monthRelation: MonthRelation.PREVIOUS_MONTH }))
})

const currentMonthDays = computed((): CalendarDay[] => {
  const days = range(0, props.month.daysInMonth!).map(index =>
    props.month.set({ day: index + 1 }),
  )
  return days.map(date => ({ date, monthRelation: MonthRelation.CURRENT_MONTH }))
})

const nextMonthDays = computed((): CalendarDay[] => {
  const amount = previousMonthDays.value.length + props.month.daysInMonth!
  const diff = 7 - (amount % 7)
  const render = range(0, diff).map(index =>
    props.month.set({ day: index + 1 }).plus({ months: 1 }),
  )
  return render.map(date => ({ date, monthRelation: MonthRelation.NEXT_MONTH }))
})

const allDays = computed((): CalendarDay[] =>
  (
    [
      ...previousMonthDays.value,
      ...currentMonthDays.value,
      ...nextMonthDays.value,
    ]
  ),
)
</script>

<style scoped src="./days.styles.css" />
