import { createInjectionState } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, toValue } from 'vue'
import { useRoute } from 'vue-router'

import { BreadcrumbsCrumbRecord, BreadcrumbsRouteCrumbs } from './types'

/**
 * Sets breadcrumbs shown by the Breadcrumbs component to the provided getter (usually taken from the route meta).
 *
 * @example
 * const routes = [{
 *   ...
 *   meta: {
 *     breadcrumbs: [
 *      // Can pass the same thing passed to router.resolve
 *      // Will automatically use .path from it
 *      { to: { name: RouteName.Dashboard } },
 *
 *      // By default will use `meta.title` from the route as the label, but can be overwritten
 *      { label: 'Custom Label', to: { name: RouteName.Campaigns } },
 *
 *      // Pass to use the current route as the breadcrumb
 *      { to: 'current' }
 *    ],
 *   },
 *   ...
 * }, {
 *   ...
 *   props: ({ params }) => ({
 *     campaignId: parseId(params.campaignId)
 *   }),
 *   meta: {
 *     // Can use the values from the current route just like in props
 *     breadcrumbs: ({ params }) => [
 *       // No need to parse the params here since they are just going into the url as a string
 *       { to: { name: RouteName.Campaign, params: { campaignId: params.campaignId } } }
 *     ]
 *   },
 *   ...
 * }]
 *
 * useProvideBreadcrumbs(() => route.meta.breadcrumbs)
 *
 * // No need to pass in the breadcrumbs explicitly (though still possible)
 * <Breadcrumbs />
 *
 * @example set breadcrumbs at the component level
 * const { data } = useApi(api.getAdGroupDetails, () => [{ adGroupId }])
 *
 * useProvideBreadcrumbs(() => [
 *   // Set breadcrumb to the name of the ad group
 *   { label: data.value?.name, to: 'current' }
 * ])
 *
 * <Breadcrumbs />
 */
export const [useProvideBreadcrumbs, useBreadcrumbs] = createInjectionState(
  (crumbs: RefOrGetter<BreadcrumbsRouteCrumbs | undefined>): Readonly<Ref<BreadcrumbsCrumbRecord[]>> => {
    const route = useRoute()

    return computed(() => {
      const _crumbs = toValue(crumbs)
      return typeof _crumbs === 'function' ? _crumbs(route) : _crumbs ?? []
    })
  },
  { fallback: () => computed(() => []) },
)
