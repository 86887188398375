<template>
  <Box inlineFlex :direction="direction" alignItems="center" data-test-id="form-choice-field" :data-test-for="id">
    <slot v-bind="{ id, disabled }" />

    <Box flex alignItems="start" spaceX="1" :class="classesContainer">
      <FormFieldLabel
        v-if="label || $slots.label"
        component="label"
        :for="id"
        :required="required"
        :disabled="disabled"
        :variant="variant"
        :color="labelColor"
        :class="classesLabel"
      >
        <slot name="label">
          {{ label }}
        </slot>
      </FormFieldLabel>

      <Tooltip
        v-if="$slots.tooltip || tooltip"
        placement="top"
        offsetDistance="8"
        class="flex"
      >
        <template #default>
          <Icon
            icon="info"
            size="xs"
            variant="outlined"
            color="textSecondaryLight"
            class="cursor-pointer"
          />
        </template>

        <template #content>
          <slot name="tooltip">
            {{ tooltip }}
          </slot>
        </template>
      </Tooltip>
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import FormFieldLabel from '../FormFieldLabel/FormFieldLabel.vue'
import Icon from '../Icon/Icon.vue'
import Tooltip from '../Tooltip/Tooltip.vue'

import { TypographyVariant } from '../Typography/types'

import type { FormChoicePosition } from './types'

const {
  position = 'right',
  label = '',
  required = false,
  disabled = false,
  variant = 'body2',
  tooltip = '',
} = defineProps<{
  position?: FormChoicePosition
  id: string
  label?: string
  required?: boolean
  disabled?: boolean
  variant?: TypographyVariant
  tooltip?: string
}>()

const direction = computed(() => position === 'left' ? 'row-reverse' : 'row')

const classesLabel = computed(() => [
  disabled ? 'cursor-default' : 'cursor-pointer',
])

const classesContainer = computed(() => [
  position === 'left' ? 'pr-2' : 'pl-2',
])

const labelColor = computed(() => {
  if (disabled) {
    return 'disabled'
  }

  return undefined
})
</script>
