import { createInjectionState } from '@lasso/shared/utils'

import { RefOrGetter } from '@lasso/shared/types'
import { toRef } from 'vue'

import { TableBaseLayout } from '../TableBase'

export type UseTableConfig = {
  layout: TableBaseLayout
}

export const [useProvideTableConfig, useTableConfig] = createInjectionState((config: RefOrGetter<UseTableConfig>) => {
  return toRef(config)
})
