import { type WatchSource, getCurrentInstance, watch } from 'vue'
import { DateTime } from 'luxon'

type RecursiveDateValue = DateTime | null | undefined | {
  [key: string]: RecursiveDateValue | RecursiveDateValue[]
}

export type DateValue = RecursiveDateValue | RecursiveDateValue[]

type DateCheck = {
  path: string
  date: string
  zone: string
  isValid: boolean
}

export function useTimezoneConsistencyCheck({
  timezone,
  datesToValidate,
  skip = false,
}: {
  timezone: WatchSource<string>
  datesToValidate: WatchSource<Record<string, DateValue>>
  skip?: boolean // Skip check when component is a part of another component that already performs the check
}) {
  if (process.env.NODE_ENV !== 'development' || skip) return

  const instance = getCurrentInstance()
  const componentName = instance?.type.__name || instance?.type.name || 'Unknown Component'

  const validateDates = (tz: string) => {
    const checks: DateCheck[] = []

    const validateDate = (path: string, date: DateTime | null) => {
      if (date) {
        checks.push({
          path,
          date: date.toString() ?? '',
          zone: date.zone.name,
          isValid: date.zone.name === tz,
        })
      }
    }

    const traverseValue = (path: string, value: DateValue) => {
      if (!value) return

      if (value instanceof DateTime) {
        validateDate(path, value)
      }
      else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item instanceof DateTime) {
            validateDate(`${path}[${index}]`, item)
          }
          else {
            traverseValue(`${path}[${index}]`, item)
          }
        })
      }
      else if (typeof value === 'object') {
        Object.entries(value).forEach(([key, val]) => {
          traverseValue(path ? `${path}.${key}` : key, val)
        })
      }
    }

    return { traverseValue, checks }
  }

  const formatChecks = (checks: DateCheck[], tz: string) => {
    const hasInvalid = checks.some(check => !check.isValid)
    if (!hasInvalid) return null

    const header = `[${componentName}] Inconsistent timezones in provided dates; ${tz} expected.`
    const list = checks
      .map(({ path, date, zone, isValid }) =>
        `${isValid ? '✅' : '❌'} ${path}: ${date} (${zone})`)
      .join('\n')

    return `${header}\n\n${list}`
  }

  watch(
    [timezone, datesToValidate],
    ([tz, datesObj]) => {
      if (!tz || !datesObj) return

      const { traverseValue, checks } = validateDates(tz)
      traverseValue('', datesObj)

      const message = formatChecks(checks, tz)
      if (message) {
        console.warn(message)
      }
    },
    { immediate: true },
  )
}
