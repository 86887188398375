<template>
  <div class="radial-progress" :class="progressRadialClasses" :style="`--value:${progress};`">
    {{ progress }}%
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import type { ProgressRadialColorType, ProgressRadialPropsType, ProgressRadialVariantType } from './types'
import { colors, variants } from './classes'

const props: ProgressRadialPropsType = defineProps({
  progress: {
    type: Number,
    required: true,
  },
  variant: {
    type: String as PropType<ProgressRadialVariantType>,
    default: null,
    validator: (variant: ProgressRadialVariantType) => !!variants[variant],
  },
  color: {
    type: String as PropType<ProgressRadialColorType>,
    default: null,
    validator: (color: ProgressRadialColorType) => !!colors[color],
  },
})

const progressRadialClasses = computed(() => {
  const variant = checkForUndefined(props.variant, variants)
  const color = checkForUndefined(props.color, colors)

  return {
    [variant]: !!props.variant,
    [color]: !!props.color,
  }
})
</script>

<style scoped src="./progressRadial.styles.css" />
