<template>
  <Grid ref="rootRef" container gap="3" xs="3">
    <Button
      v-for="(yearItem, index) in years"
      :key="index"
      :data-test-id="isActive(yearItem) ? `selected-${yearItem.year}` : yearItem.year"
      :variant="buttonVariant(yearItem)"
      :color="buttonColor(yearItem)"
      :disabled="disabled"
      @pointerdown="handleClick(yearItem)"
    >
      <Typography :color="typographyColor(yearItem)">
        {{ yearItem.toFormat(dateFormat) }}
      </Typography>
    </Button>
  </Grid>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, ref, watchEffect } from 'vue'

import { DateTime } from 'luxon'
import { useVModel } from '@vueuse/core'
import { range } from 'lodash-es'

import Grid from '../../../Grid/Grid.vue'
import Button from '../../../Button/Button.vue'
import Typography from '../../../Typography/Typography.vue'

const props = withDefaults(defineProps<{
  modelValue: DateTime
  dateFormat?: string
  min?: DateTime
  max?: DateTime
  disabled?: boolean
}>(), {
  dateFormat: 'yyyy',
})

const emits = defineEmits<{
  'update:modelValue': [DateTime]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emits)
const rootRef = ref<ComponentPublicInstance>()

const handleClick = (yearItem: DateTime) => {
  modelValueInternal.value = yearItem
}

const years = computed(() => {
  const start = modelValueInternal.value.minus({ years: 100 })
  return range(200).map((_, index) => start.plus({ years: index }))
})

/**
 * isDisabled helper is used to disable months that are out of the min/max range
 * props.disabled disables the whole component
 */
const isDisabled = (yearItem: DateTime) => {
  if (props.min && yearItem.endOf('year').set({ hour: 23, minute: 59, second: 59, millisecond: 0 }) < props.min) {
    return true
  }
  if (props.max && yearItem.startOf('year') > props.max) {
    return true
  }
  return false
}

const isActive = (yearItem: DateTime) => {
  return modelValueInternal.value.hasSame(yearItem, 'year')
}

const buttonVariant = (yearItem: DateTime) => {
  if (isActive(yearItem)) {
    return 'default'
  }
  else {
    return 'ghost'
  }
}

const buttonColor = (yearItem: DateTime) => {
  if (isActive(yearItem)) {
    return 'primary'
  }
  else {
    return 'default'
  }
}

const typographyColor = (yearItem: DateTime) => {
  if (isActive(yearItem)) {
    return 'inherit'
  }
  else if (isDisabled(yearItem)) {
    return 'disabled'
  }
  else {
    return 'inherit'
  }
}

watchEffect(() => {
  if (rootRef.value) {
    rootRef.value.$el.querySelector(`[data-test-id="selected-${modelValueInternal.value.year}"]`)?.scrollIntoView({ block: 'center' })
  }
})
</script>
