<template>
  <DropdownList
    :id="id"
    ref="dropdownListRef"
    class="select-dropdown"
    :class="classes"
    :width="dropdownWidth"
    :loadingMore="loadingMore"
    trigger="manual"
    @hide="$emit('hide')"
    @show="$emit('open')"
    @clickOutside="handleClickOutside"
  >
    <Skeleton v-if="loading" height="36px" />
    <Box v-else ref="triggerWrapperRef" width="100%">
      <slot name="trigger" v-bind="slotProps">
        <InputWrapper
          data-test-id="select-input-wrapper"
          :disabled="disabled"
          :focused="focused"
          :error="hasError"
          :variant="variant"
          :size="size"
          class="select-input-wrapper"
          @click="toggle"
        >
          <InputIcon
            :icon="icon"
            :endIcon="hideArrow ? undefined : 'keyboard_arrow_down'"
            class="select-input-icon"
          >
            <SelectPlaceholder v-if="isEmpty" :disabled="disabled">
              {{ placeholder }}
            </SelectPlaceholder>

            <SelectValue
              v-else
              data-test-id="select-value"
              :multiple="multiple"
              :options="selectedOptions"
              :disabled="disabled"
              :cancelable="cancelable"
              @cancel="deleteOption"
            />
          </InputIcon>
        </InputWrapper>
      </slot>
    </Box>

    <template v-if="!hideDropdown" #dropdown="scope">
      <slot name="dropdown" v-bind="slotProps">
        <template v-if="scope.state.isVisible || scope.state.isShown">
          <template v-if="options && options.length > 0">
            <ListItemButton
              v-for="(option, index) in options"
              :key="index"
              :active="isActiveOption(option)"
              :disabled="option.disabled"
              data-test-id="select-option"
              @click="handleOptionClick(option, scope.hide)"
            >
              <Typography variant="inherit" wordBreak="anywhere">
                <Chip v-if="withValue" size="3xs" mr="1">
                  {{ option.value }}
                </Chip>
                <Highlight :label="option.label" :highlight="highlight" />
              </Typography>
            </ListItemButton>
          </template>
          <template v-else>
            <ListItemButton disabled>
              <Typography variant="inherit">
                {{ noOptionsMessage }}
              </Typography>
            </Listitembutton>
          </template>
        </template>
      </slot>
      <slot name="infiniteScroll" v-bind="slotProps" />
    </template>
  </DropdownList>
</template>

<script lang="ts" setup>
import { Ref, computed, inject, onMounted, ref, watch } from 'vue'

import { useElementBounding } from '@vueuse/core'

import DropdownList from '../DropdownList/DropdownList.vue'
import InputIcon from '../Input/InputIcon.vue'
import InputWrapper from '../Input/InputWrapper/InputWrapper.vue'
import ListItemButton from '../ListItemButton/ListItemButton.vue'
import Skeleton from '../Skeleton/Skeleton.vue'
import Highlight from '../Highlight/Highlight.vue'
import Box from '../Box/Box.vue'
import { InputWrapperSize, InputWrapperVariant } from '../Input/InputWrapper/types'
import { Chip } from '../Chip'

import { Typography } from '../Typography'

import SelectValue from './SelectValue.vue'
import SelectPlaceholder from './SelectPlaceholder.vue'

import type { SelectModelValueType, SelectOptionType, SelectOptionValueType } from './types'

const props = withDefaults(defineProps<{
  modelValue?: SelectModelValueType
  name?: string
  id?: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  multiple?: boolean
  icon?: string
  loading?: boolean
  loadingMore?: boolean
  options?: readonly SelectOptionType[]
  hideArrow?: boolean
  highlight?: string
  width?: string
  cancelable?: boolean
  variant?: InputWrapperVariant
  size?: InputWrapperSize
  withValue?: boolean
  noOptionsMessage?: string
  hideDropdown?: boolean
  objectValue?: boolean
}>(), {
  modelValue: '',
  name: '',
  id: '',
  disabled: false,
  error: false,
  placeholder: '',
  multiple: false,
  icon: '',
  loading: false,
  loadingMore: false,
  options: () => [],
  hideArrow: false,
  highlight: '',
  cancelable: false,
  size: 'lg',
  withValue: false,
  noOptionsMessage: 'No options available',
  hideDropdown: false,
  objectValue: false,
})

const emits = defineEmits(['update:modelValue', 'select-option', 'open', 'hide', 'click-outside'])

const modelProxy = ref<SelectModelValueType>('')
const selectedValues = ref<SelectOptionValueType[]>([])

/*
  This is provided from <FormField> component
  it returns boolean error state of vee validate
 */
const formControlHasError = inject<Ref<boolean>>('formControlHasError', ref(false))
const hasError = computed(() => formControlHasError.value || props.error || false)

const selectedOptions = computed(() => {
  return selectedValues.value.reduce((acc, cur) => {
    const option = props.options.find(item => item.value === cur)

    if (option) {
      acc.push(option)
    }

    return acc
  }, [] as SelectOptionType[])
})

const focused = ref(false)
const opened = ref<boolean>(false)
const isEmpty = computed(() => selectedValues.value.length === 0)

const dropdownListRef = ref()

const open = () => {
  if (props.disabled || props.loading)
    return

  dropdownListRef.value?.show?.()
  opened.value = true
  focused.value = true
}

const hide = () => {
  if (props.disabled)
    return

  dropdownListRef.value?.hide?.()
  opened.value = false
  focused.value = false
}

const scrollToTop = () => {
  dropdownListRef.value?.scrollToTop?.()
}

const toggle = () => {
  opened.value ? hide() : open()
}

const handleClickOutside = () => {
  hide()
  emits('click-outside')
}

const isObjectValue = (value: SelectOptionValueType): value is SelectOptionType<any> => typeof value === 'object'
const getOptionValue = (optionValue: SelectOptionType['value']) => isObjectValue(optionValue) ? optionValue.value : optionValue

function setValue() {
  // @ts-expect-error TODO: rewrite this component
  modelProxy.value = props.multiple ? selectedValues.value : selectedValues.value?.[0]
  emits('update:modelValue', modelProxy.value)
}

const selectOption = (option: SelectOptionType, emit = true) => {
  if (props.multiple) {
    const idx = selectedValues.value.findIndex(item => getOptionValue(item) === getOptionValue(option.value))

    if (idx !== -1) {
      selectedValues.value.splice(idx, 1)
    }
    else {
      props.objectValue ? selectedValues.value.push(option) : selectedValues.value.push(option.value)
    }
    hide()
  }
  else {
    selectedValues.value = []
    selectedValues.value.push(option.value)
    hide()
  }

  if (emit) {
    setValue()
  }
  emits('select-option', option)
}

const handleOptionClick = (option: SelectOptionType, close: () => void) => {
  selectOption(option)
  close()
}

const deleteOption = (option: SelectOptionType) => {
  if (!isEmpty.value && !props.disabled) {
    selectedValues.value = selectedValues.value.filter(item => item !== option.value)
    hide()
    setValue()
  }
}
const isActiveOption = (option: SelectOptionType) => {
  return selectedValues.value?.some(item => getOptionValue(item) === getOptionValue(option.value))
}

function parseModel() {
  const model = props.modelValue
  const options = props.options || []
  const values = options?.map(option => option.value)

  selectedValues.value = []
  modelProxy.value = props.modelValue

  if (model) {
    if (Array.isArray(model)) {
      model.forEach((value) => {
        const idx = values.findIndex(item => getOptionValue(item) === getOptionValue(value))

        if (idx >= 0) {
          selectedValues.value.push(props.objectValue ? options[idx]! : options[idx]!.value)
        }
        else {
          selectedValues.value.push(value)
        }
      })
    }
    else {
      const idx = values.findIndex(item => getOptionValue(item) === getOptionValue(model))

      if (idx >= 0) {
        selectOption(options[idx]!, false)
      }
      else {
        selectedValues.value.push(model)
      }
    }
  }
}

const slotProps = computed(() => ({
  disabled: props.disabled,
  error: props.error,
  selectedOptions: selectedOptions.value,
  open,
  hide,
  deleteOption,
}))

const triggerWrapperRef = ref<HTMLElement>()
const { width: triggerWrapperWidth } = useElementBounding(triggerWrapperRef)
const dropdownWidth = computed(() => props.width || `${triggerWrapperWidth.value}px`)

const classes = computed(() => ({
  'opened': opened.value,
  'loading': props.loading,
  'disabled': props.disabled,
  'with-icon': !!props.icon,
  'select-dropdown-lg': props.size === 'lg',
  'select-dropdown-md': props.size === 'md',
  'select-dropdown-sm': props.size === 'sm',
}))

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      parseModel()
    }
  },
)

onMounted(() => {
  parseModel()
})

defineExpose({
  open,
  hide,
  scrollToTop,
})
</script>

<style scoped>
.select-dropdown.opened :deep(.end-input-icon) {
  @apply -rotate-180;
}

.select-dropdown :deep(.dropdown) {
  @apply mt-2;
}

.select-dropdown.loading {
  @apply h-9;
}

.select-dropdown.with-icon .select-input-wrapper {
  @apply pl-2;
}

.select-dropdown :deep(.input-icon) {
  @apply min-h-[22px];
}

.select-dropdown :deep(.input-icon .end-input-icon) {
  @apply transition-transform select-none;
}

.select-dropdown:not(.disabled) :deep(.input-icon) {
  @apply cursor-pointer;
}

.select-dropdown :deep(.input-wrapper) {
  @apply pl-4 pr-2;
}

.select-dropdown-lg :deep(.input-wrapper) {
  @apply py-[6px];
}

.select-dropdown-md :deep(.input-wrapper) {
  @apply py-[4px];
}

.select-dropdown-sm :deep(.input-wrapper) {
  @apply py-[2px];
}
</style>
