import { CheckboxColor, CheckboxSize } from './types'

export const sizes: Record<CheckboxSize, string> = {
  sm: 'lui-checkbox-sm',
  md: 'lui-checkbox-md',
}

export const colors: Record<CheckboxColor, string> = {
  primary: 'lui-checkbox-primary',
  secondary: 'lui-checkbox-secondary',
}
