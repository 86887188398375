<template>
  <DraggableNestedBase
    v-bind="{
      itemComponent,
      itemProps,
      group,
      convertItem,
      maxDepth,
      draggableProps,
      emptyInsertThreshold,
      addItem,
    }"
  />
</template>

<script setup lang="ts" generic="T extends { items?: T[]; }">
import { DraggableNestedProps } from './types'

import { useProvideDraggableNestedState } from './useDraggableNestedState'
import DraggableNestedBase from './DraggableNestedBase.vue'

const {
  itemComponent,
  itemProps,
  group,
  convertItem,
  maxDepth = Infinity,
  draggableProps,
  emptyInsertThreshold,
  addItem,
} = defineProps<DraggableNestedProps<T>>()

const modelValueInternal = defineModel<T>({ required: true })

useProvideDraggableNestedState(modelValueInternal)
</script>
