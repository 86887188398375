<template>
  <Grid container gap="3" width="auto" xs="3">
    <Button
      v-for="(monthItem, calendarMonth) in months"
      :key="calendarMonth"
      size="lg"
      :variant="buttonVariant(calendarMonth)"
      :color="buttonColor(calendarMonth)"
      :disabled="disabled"
      :data-test-id="isActive(calendarMonth) ? `selected-${monthItem.month}` : monthItem.month"
      @pointerdown="handleSetMonth(monthItem)"
    >
      <Typography :color="typographyColor(calendarMonth)">
        {{ monthItem.toFormat(dateFormat) }}
      </Typography>
    </Button>
  </Grid>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { DateTime } from 'luxon'
import { useVModel } from '@vueuse/core'

import Grid from '../../../Grid/Grid.vue'
import Button from '../../../Button/Button.vue'
import Typography from '../../../Typography/Typography.vue'

const props = withDefaults(defineProps<{
  modelValue: DateTime
  dateFormat?: string
  min?: DateTime
  max?: DateTime
  disabled?: boolean
}>(), {
  dateFormat: 'LLL',
})

const emits = defineEmits<{
  'update:modelValue': [DateTime]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emits)

const months = computed(() => Array(12).fill(0).map((_, idx) => modelValueInternal.value.set({ month: idx + 1 })))

/**
 * isDisabled helper is used to disable months that are out of the min/max range
 * props.disabled disables the whole component
 */
const isDisabled = (month: DateTime) => {
  if (props.min && month.endOf('month').endOf('day') < props.min) {
    return true
  }
  if (props.max && month.startOf('month') > props.max) {
    return true
  }
  return false
}

const isActive = (calendarMonth: number) => {
  return modelValueInternal.value.month === calendarMonth + 1
}

const handleSetMonth = (month: DateTime) => {
  modelValueInternal.value = month
}

const buttonVariant = (calendarMonth: number) => {
  if (isActive(calendarMonth)) {
    return 'default'
  }
  else {
    return 'ghost'
  }
}

const buttonColor = (calendarMonth: number) => {
  if (isActive(calendarMonth)) {
    return 'primary'
  }
  else {
    return 'default'
  }
}

const typographyColor = (calendarMonth: number) => {
  if (isActive(calendarMonth)) {
    return 'inherit'
  }
  else if (isDisabled(months.value[calendarMonth]!)) {
    return 'disabled'
  }
  else {
    return 'inherit'
  }
}
</script>
