<template>
  <ol class="progress-steps" :class="stepsListClasses">
    <slot />
  </ol>
</template>

<script lang="ts" setup>
/* eslint-disable vue/enforce-style-attribute */
import type { PropType } from 'vue'
import { computed, provide, reactive } from 'vue'

import { checkForUndefined } from '../../utils'

import * as classes from './classes'

import type {
  ProgressStepsListColor,
  ProgressStepsListDirection,
  ProgressStepsListProps,
} from './types'

const props: ProgressStepsListProps = defineProps({
  color: {
    type: String as PropType<ProgressStepsListColor>,
    default: 'textSecondaryLight',
    validator: (color: ProgressStepsListColor) => [
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'accent',
    ].includes(color),
  },
  direction: {
    type: String as PropType<ProgressStepsListDirection>,
    default: 'horizontal',
    validator: (direction: ProgressStepsListDirection) => !!classes.direction[direction],
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
})

const stepsListClasses = computed(() => {
  const direction = checkForUndefined(props.direction, classes.direction)

  return {
    [direction]: !!props.direction,
  }
})

const state = reactive({
  hideLabel: props.hideLabel,
  color: props.color,
  direction: props.direction,
})

provide('StepsListState', state)
</script>

<style src="./progressStepList.styles.css"></style>
