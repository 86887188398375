<template>
  <Tippy
    ref="tippyRef"
    trigger="manual"
    :hideOnClick="false"
    @show="handlePickerShow"
    @hidden="handlePickerHide"
    @clickOutside="handleClickOutside"
  >
    <Box flex :wrap="wrapInternal" alignItems="start" :spaceX="spaceXInternal" :spaceY="spaceYInternal" class="isolate">
      <FormField
        :id="startDateId"
        v-slot="{ id }"
        ref="startFormControlRef"
        :label="startLabel"
        :errorText="startError"
        width="auto"
        :required="startRequired"
        class="z-10 hover:z-20 focus-within:z-20"
      >
        <Box flex alignItems="center">
          <InputDateField
            :id="id"
            ref="startDateInputRef"
            v-model="startDate"
            data-test-id="input-date-field-start"
            class="z-0 focus-within:z-10"
            :class="{ 'with-right-neighbor': displayTime || hideGap }"
            :dateFormat="dateFormat"
            :placeholder="startPlaceholder"
            :disabled="startDateDisabled"
            :timezone="timezone"
            :locale="locale"
            :min="minDateInternal"
            :max="maxDateInternal"
            :disabledPeriods="disabledPeriods"
            autocomplete="off"
            @focus="handleInputDateFieldFocus('start')"
            @blur="handleFieldBlur"
          />
          <Tooltip class="z-0 hover:z-10" :content="timeTooltip">
            <InputTimeField
              v-if="displayTime"
              ref="startTimeInputRef"
              v-model="startTime"
              data-test-id="input-time-field-start"
              class="with-left-neighbor"
              :class="{
                'with-right-neighbor': displayEndDate && hideGap,
              }"
              placeholder="12:00 AM"
              :disabled="startTimeDisabled || startDateDisabled"
              autocomplete="off"
              @focus="handleInputTimeFieldFocus('start')"
              @blur="handleFieldBlur"
            />
          </Tooltip>
        </Box>
      </FormField>

      <FormField
        v-if="displayEndDate"
        :id="endDateId"
        v-slot="{ id }"
        ref="endFormControlRef"
        :label="endLabel"
        :errorText="endError"
        width="auto"
        :required="endRequired"
        :class="{
          'z-10': endError || endFormControlHasError,
          'hover:z-10 focus-within:z-10': !startError && !startFormControlHasError,
          'z-0 hover:z-30 focus-within:z-30': startError || startFormControlHasError,
        }"
      >
        <Box flex alignItems="center">
          <InputDateField
            :id="id"
            ref="endDateInputRef"
            v-model="endDate"
            data-test-id="input-date-field-end"
            class="z-0 focus-within:z-10"
            :class="{
              'with-left-neighbor': hideGap,
              'with-right-neighbor': displayTime,
            }"
            :dateFormat="dateFormat"
            :placeholder="endPlaceholder"
            :disabled="endDateDisabled"
            :timezone="timezone"
            :locale="locale"
            :min="minDateInternal"
            :max="maxDateInternal"
            :disabledPeriods="disabledPeriods"
            autocomplete="off"
            @focus="handleInputDateFieldFocus('end')"
            @blur="handleFieldBlur"
          />
          <Tooltip class="z-0 hover:z-10" :content="timeTooltip">
            <InputTimeField
              v-if="displayTime"
              ref="endTimeInputRef"
              v-model="endTime"
              data-test-id="input-time-field-end"
              class="with-left-neighbor"
              placeholder="11:59 PM"
              :disabled="endTimeDisabled || endDateDisabled"
              autocomplete="off"
              @focus="handleInputTimeFieldFocus('end')"
              @blur="handleFieldBlur"
            />
          </Tooltip>
        </Box>
      </FormField>
    </Box>

    <template #content>
      <DatePicker
        v-if="isShown"
        v-bind="$attrs"
        data-test-id="date-picker"
        :periodDates="modelValueInternal"
        mode="period"
        noPadding
        :dateFormat="dateFormat"
        :min="minDateInternal"
        :max="maxDateInternal"
        :disabled="startDateDisabled && endDateDisabled"
        :disabledPeriods="disabledPeriods"
        :activePeriodPosition="activePeriodPosition"
        :initialMonth="initialMonth"
        :timezone="timezone"
        skipDevOnlyTimezoneCheck
        @update:periodDates="handlePeriodDatesUpdate"
        @mousedown.stop.prevent
      />
    </template>
  </Tippy>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { TimeZone } from '@lasso/shared/consts'

import Box from '../../Box/Box.vue'
import { BoxSpacing, BoxWrap } from '../../Box/types'

import FormField from '../../FormField/FormField.vue'
import Tooltip from '../../Tooltip/Tooltip.vue'
import Tippy from '../../Tippy/Tippy.vue'

import DatePicker from '../../DatePicker/DatePicker.vue'
import { DateFormatTypes, DisabledPeriod, PeriodPosition } from '../../DatePicker/types'

import InputDateField from '../InputDateField/InputDateField.vue'
import InputTimeField from '../InputTimeField/InputTimeField.vue'

import { useTimezoneConsistencyCheck } from '../../../hooks/useTimezoneConsistencyCheck'
import { getHour, getMinute, isShortDateFormat, normalizeTimezoneIfNeeded } from '../utils'

const props = withDefaults(defineProps<{
  dataTestId?: string
  startDateId?: string
  endDateId?: string
  modelValue: { start: DateTime | null; end: DateTime | null }
  displayTime?: boolean
  displayEndDate?: boolean
  startDateDisabled?: boolean
  endDateDisabled?: boolean
  startTimeDisabled?: boolean
  endTimeDisabled?: boolean
  startLabel?: string
  endLabel?: string
  startPlaceholder?: string
  endPlaceholder?: string
  locale?: string
  dateFormat?: DateFormatTypes
  min?: DateTime | undefined
  max?: DateTime | undefined
  disabledPeriods?: DisabledPeriod[]
  startError?: string
  endError?: string
  timeTooltip?: string
  startRequired?: boolean
  endRequired?: boolean
  spaceX?: BoxSpacing
  spaceY?: BoxSpacing
  wrap?: BoxWrap
  hideGap?: boolean
  timezone: TimeZone
  defaultStartTime?: { hour: number; minute: number }
  defaultEndTime?: { hour: number; minute: number }
}>(), {
  displayTime: false,
  displayEndDate: true,
  startDateDisabled: false,
  endDateDisabled: false,
  startTimeDisabled: false,
  endTimeDisabled: false,
  startLabel: '',
  endLabel: '',
  startPlaceholder: '',
  endPlaceholder: '',
  locale: 'en-US',
  dateFormat: 'MM/dd/yyyy',
  disabledPeriods: () => [],
  startError: '',
  endError: '',
  timeTooltip: '',
  startDateId: 'startDate',
  endDateId: 'endDate',
  startRequired: false,
  endRequired: false,
  spaceX: '6',
  spaceY: '6',
  wrap: 'wrap',
  hideGap: false,
  defaultStartTime: () => ({ hour: 0, minute: 0 }),
  defaultEndTime: () => ({ hour: 0, minute: 0 }),
})

const emits = defineEmits<{
  show: []
  hide: []
  'update:modelValue': [{ start: DateTime | null; end: DateTime | null }]
}>()

defineOptions({
  inheritAttrs: false,
})

/*
 * The modelValueInternal normalizes dates from modelValue to the timezone provided in the props;
 * The further date manipulations within the component will be done in the timezone provided in the props;
 * Such normalized date will be emitted from the component only with the first update:modelValue event;
 */
const modelValueInternal = computed<{ start: DateTime | null; end: DateTime | null }>({
  get: () => {
    return {
      start: props.modelValue.start && normalizeTimezoneIfNeeded(props.modelValue.start, props.timezone),
      end: props.modelValue.end && normalizeTimezoneIfNeeded(props.modelValue.end, props.timezone),
    }
  },
  set: (newValue) => {
    emits('update:modelValue', newValue)
  },
})

const minDateInternal = computed(() => props.min && normalizeTimezoneIfNeeded(props.min, props.timezone))
const maxDateInternal = computed(() => props.max && normalizeTimezoneIfNeeded(props.max, props.timezone))

const startFormControlRef = ref<InstanceType<typeof FormField> | null>(null)
const startDateInputRef = ref<InstanceType<typeof InputDateField> | null>(null)
const startTimeInputRef = ref<InstanceType<typeof InputTimeField> | null>(null)

const endFormControlRef = ref<InstanceType<typeof FormField> | null>(null)
const endDateInputRef = ref<InstanceType<typeof InputDateField> | null>(null)
const endTimeInputRef = ref<InstanceType<typeof InputTimeField> | null>(null)

const endFormControlHasError = computed(() => endFormControlRef.value?.hasError)
const startFormControlHasError = computed(() => startFormControlRef.value?.hasError)

const spaceXInternal = computed(() => props.hideGap ? undefined : props.spaceX)
const spaceYInternal = computed(() => props.hideGap ? undefined : props.spaceY)
const wrapInternal = computed(() => props.hideGap ? undefined : props.wrap)

const isShown = ref(false)
const activePeriodPosition = ref<PeriodPosition>('start')
const tippyRef = ref<InstanceType<typeof Tippy> | null>(null)

const startTimeInternal = ref<{ hour: string; minute: string }>({ hour: '', minute: '' })
const endTimeInternal = ref<{ hour: string; minute: string }>({ hour: '', minute: '' })

const hasStartTime = computed(() => startTimeInternal.value.hour && startTimeInternal.value.minute)
const hasEndTime = computed(() => endTimeInternal.value.hour && endTimeInternal.value.minute)

// Helper for converting string time to DateTime object
const timeToDateObject = (time: { hour: string; minute: string }) => {
  return {
    hour: parseInt(time.hour) || 0,
    minute: parseInt(time.minute) || 0,
    second: 0,
    millisecond: 0,
  }
}

// Preserve the time when the modelValue changes
watch(() => modelValueInternal.value.start, (newValue) => {
  if (newValue) {
    startTimeInternal.value = {
      hour: getHour(newValue),
      minute: getMinute(newValue),
    }
  }
  else {
    startTimeInternal.value = { hour: '', minute: '' }
  }
}, { immediate: true })

watch(() => modelValueInternal.value.end, (newValue) => {
  if (newValue) {
    endTimeInternal.value = {
      hour: getHour(newValue),
      minute: getMinute(newValue),
    }
  }
  else {
    endTimeInternal.value = { hour: '', minute: '' }
  }
}, { immediate: true })

const initialMonth = computed(() => {
  return activePeriodPosition.value === 'start' ? modelValueInternal.value?.start : modelValueInternal.value?.end
})

const handleDateInput = (newDateString: string, periodPosition: PeriodPosition) => {
  const currentDate = periodPosition === 'start' ? modelValueInternal.value.start : modelValueInternal.value.end
  const timeInternal = periodPosition === 'start' ? startTimeInternal.value : endTimeInternal.value
  const hasTimeValue = periodPosition === 'start' ? hasStartTime.value : hasEndTime.value
  const { hour: defaultHour, minute: defaultMinute } = periodPosition === 'start' ? props.defaultStartTime : props.defaultEndTime

  const newDate = DateTime.fromFormat(newDateString, props.dateFormat, {
    locale: props.locale,
    zone: props.timezone,
  })

  if (!newDate.isValid) {
    return currentDate
  }

  let resultDate = newDate

  if (hasTimeValue) {
    resultDate = resultDate.set(timeToDateObject(timeInternal))
  }
  else if (currentDate) {
    resultDate = resultDate.set({
      hour: currentDate.hour,
      minute: currentDate.minute,
      second: 0,
      millisecond: 0,
    })
  }
  else {
    resultDate = resultDate.set({
      hour: defaultHour,
      minute: defaultMinute,
      second: 0,
      millisecond: 0,
    })
  }

  return resultDate
}

const switchFocusToField = (targetPosition: PeriodPosition) => {
  activePeriodPosition.value = targetPosition
  const inputRef = targetPosition === 'start' ? startDateInputRef : endDateInputRef
  inputRef.value?.$el.querySelector('input')?.focus()
}

const handlePeriodDatesUpdate = (dates: { start: DateTime | null; end: DateTime | null }) => {
  const updatedDates = { ...dates }

  if (updatedDates.start) {
    updatedDates.start = updatedDates.start.set(hasStartTime.value ? timeToDateObject(startTimeInternal.value) : props.defaultStartTime)
  }

  if (updatedDates.end) {
    updatedDates.end = updatedDates.end.set(hasEndTime.value ? timeToDateObject(endTimeInternal.value) : props.defaultEndTime)
  }

  const prevStart = modelValueInternal.value.start
  const prevEnd = modelValueInternal.value.end
  const currentPosition = activePeriodPosition.value

  modelValueInternal.value = updatedDates

  if (!modelValueInternal.value.start) {
    startTimeInternal.value = { hour: '', minute: '' }
  }
  if (!modelValueInternal.value.end) {
    endTimeInternal.value = { hour: '', minute: '' }
  }

  // Prevent the logic for switching between fields for short date format
  // TODO: remove this after rewriting the month selection logic
  if (isShortDateFormat(props.dateFormat)) {
    return
  }

  // Logic for switching between fields
  if (!prevStart && !prevEnd) {
    if (updatedDates.start && currentPosition === 'start') {
      switchFocusToField('end')
    }
    else if (updatedDates.end && currentPosition === 'end') {
      switchFocusToField('start')
    }
  }
  else if (prevStart && prevEnd) {
    if (currentPosition === 'start' && updatedDates.start) {
      switchFocusToField('end')
    }
  }
  else if (prevStart && !prevEnd && updatedDates.start && currentPosition === 'start') {
    switchFocusToField('end')
  }
  else if (!prevStart && prevEnd) {
    if (currentPosition === 'start' && updatedDates.start) {
      switchFocusToField('end')
    }
    else if (currentPosition === 'end' && updatedDates.end) {
      switchFocusToField('start')
    }
  }
}

const handleInputDateFieldFocus = (periodPosition: PeriodPosition) => {
  activePeriodPosition.value = periodPosition
  tippyRef.value?.tippyInstance?.show()
}

const updateModelValueInternal = (position: 'start' | 'end', newDate: DateTime | null) => {
  const currentStart = modelValueInternal.value.start
  const currentEnd = modelValueInternal.value.end

  if (position === 'start') {
    modelValueInternal.value = {
      start: newDate,
      end: currentEnd,
    }
  }
  else {
    modelValueInternal.value = {
      start: currentStart,
      end: newDate,
    }
  }
}

const handleFieldBlur = (
  blurEvent: FocusEvent,
) => {
  const relatedTarget = blurEvent.relatedTarget

  const isStayWithinComponent = relatedTarget && (
    startDateInputRef.value?.$el.contains(relatedTarget)
    || endDateInputRef.value?.$el.contains(relatedTarget)
    || startTimeInputRef.value?.$el.contains(relatedTarget)
    || endTimeInputRef.value?.$el.contains(relatedTarget)
    || tippyRef.value?.$el.contains(relatedTarget)
  )

  if (isStayWithinComponent) {
    return
  }

  const startDateInputValue = startDateInputRef.value?.$el.querySelector('input')?.value ?? ''
  const shouldClearStartDate = startDateInputValue.length < props.dateFormat.length

  const endDateInputValue = endDateInputRef.value?.$el.querySelector('input')?.value ?? ''
  const shouldClearEndDate = endDateInputValue.length < props.dateFormat.length

  if (shouldClearStartDate || shouldClearEndDate) {
    modelValueInternal.value = {
      start: shouldClearStartDate ? null : modelValueInternal.value.start,
      end: shouldClearEndDate ? null : modelValueInternal.value.end,
    }

    if (shouldClearStartDate) {
      startTimeInternal.value = { hour: '', minute: '' }
    }
    if (shouldClearEndDate) {
      endTimeInternal.value = { hour: '', minute: '' }
    }
  }

  tippyRef.value?.tippyInstance?.hide()
}

const handleInputTimeFieldFocus = (periodPosition: PeriodPosition) => {
  activePeriodPosition.value = periodPosition
}

const startDate = computed({
  get: () => {
    return modelValueInternal.value.start?.toFormat(props.dateFormat) || ''
  },
  set: (newDate: string | null) => {
    const prevStart = modelValueInternal.value.start
    const prevEnd = modelValueInternal.value.end

    const newStartDate = handleDateInput(newDate ?? '', 'start')
    updateModelValueInternal('start', newStartDate)

    // If both dates were empty and start was set - switch to end
    if (!prevStart && !prevEnd && newStartDate) {
      switchFocusToField('end')
    }
    // If both dates were filled and start was changed - switch to end
    else if (prevStart && prevEnd && newStartDate && newStartDate !== prevStart) {
      switchFocusToField('end')
    }
  },
})

const endDate = computed({
  get: () => {
    return modelValueInternal.value.end?.toFormat(props.dateFormat) || ''
  },
  set: (newDate: string | null) => {
    const prevStart = modelValueInternal.value.start
    const prevEnd = modelValueInternal.value.end

    const newEndDate = handleDateInput(newDate ?? '', 'end')
    updateModelValueInternal('end', newEndDate)

    // If start was empty and end was set - switch to start
    if (!prevStart && !prevEnd && newEndDate) {
      switchFocusToField('start')
    }
  },
})

const startTime = computed({
  get: () => {
    if (modelValueInternal.value.start) {
      return {
        hour: getHour(modelValueInternal.value.start),
        minute: getMinute(modelValueInternal.value.start),
      }
    }
    else if (hasStartTime.value) {
      return startTimeInternal.value
    }
    return {
      hour: '',
      minute: '',
    }
  },
  set: ({ hour, minute }) => {
    if (!hour || !minute) {
      return
    }

    startTimeInternal.value = { hour, minute }

    if (modelValueInternal.value.start && DateTime.isDateTime(modelValueInternal.value.start)) {
      updateModelValueInternal('start', modelValueInternal.value.start.set(timeToDateObject(startTimeInternal.value)))
    }
  },
})

const endTime = computed({
  get: () => {
    if (modelValueInternal.value.end) {
      return {
        hour: getHour(modelValueInternal.value.end),
        minute: getMinute(modelValueInternal.value.end),
      }
    }
    else if (hasEndTime.value) {
      return endTimeInternal.value
    }
    return {
      hour: '',
      minute: '',
    }
  },
  set: ({ hour, minute }) => {
    if (!hour || !minute) {
      return
    }

    endTimeInternal.value = { hour, minute }

    if (modelValueInternal.value.end && DateTime.isDateTime(modelValueInternal.value.end)) {
      updateModelValueInternal('end', modelValueInternal.value.end.set(timeToDateObject(endTimeInternal.value)))
    }
  },
})

const handlePickerShow = () => {
  emits('show')
  isShown.value = true
}

const handlePickerHide = () => {
  emits('hide')
  isShown.value = false
}

const handleClickOutside = (instance: any) => {
  const target = instance?.reference
  const clickedElement = instance?.event?.target as HTMLElement

  if (target?.contains(clickedElement)) {
    return
  }

  tippyRef.value?.tippyInstance?.hide()
}

useTimezoneConsistencyCheck({
  timezone: () => props.timezone,
  datesToValidate: () => ({
    start: props.modelValue.start,
    end: props.modelValue.end,
    min: props.min,
    max: props.max,
    disabledPeriods: props.disabledPeriods,
  }),
})
</script>

<style scoped src="./inputdaterange.styles.css" />
