<template>
  <Box ref="panelRef" :p="panelPadding" flex col width="326px" data-test-id="datepicker-panel">
    <Box flex row justify="between" alignItems="center">
      <ButtonIcon
        :class="{ invisible: hideBack }"
        icon="navigate_before"
        :disabled="disabled"
        data-test-id="panel-nav-prev"
        @click="navigateMonth(-1)"
      />
      <Box flex row justify="center">
        <Link
          class="mr-1"
          :class="{ active: panelMode === PanelMode.MonthView }"
          :disabled="disabled"
          @click="switchToMonthView"
        >
          <Typography data-test-id="month" variant="body1">
            {{ month.toFormat(monthFormat) }}
          </Typography>
        </Link>
        <Link
          :class="{ active: panelMode === PanelMode.YearView }"
          :disabled="disabled"
          @click="switchToYearView"
        >
          <Typography data-test-id="year" variant="body1">
            {{ month.toFormat(yearFormat) }}
          </Typography>
        </Link>
      </Box>
      <ButtonIcon
        :class="{ invisible: hideForward }"
        icon="keyboard_arrow_right"
        :disabled="disabled"
        data-test-id="panel-nav-next"
        @click="navigateMonth(1)"
      />
    </Box>
    <Weekdays
      v-if="panelMode === PanelMode.DayView"
      :locale="locale"
      :firstDayOfTheWeek="firstDayOfTheWeek"
    />
    <Box py="4" flex col justify="center" height="258px">
      <Box class="overflow-y-auto">
        <Days
          v-if="panelMode === PanelMode.DayView"
          :datePickerMode="datePickerMode"
          :singleDate="singleDate"
          :periodDates="periodDates"
          :multipleDates="multipleDates"
          :periodPosition="periodPosition"
          :month="month"
          :max="max"
          :min="min"
          :disabled="disabled"
          :disabledPeriods="disabledPeriods"
          :disabledWeekdays="disabledWeekdays"
          :firstDayOfTheWeek="firstDayOfTheWeek"
          :timezone="timezone"
          :locale="locale"
          @update:singleDate="handleSingleDateUpdate"
          @update:periodDates="handlePeriodDatesUpdate"
          @update:multipleDates="handleMultipleDatesUpdate"
        />
        <Months
          v-if="panelMode === PanelMode.MonthView"
          :modelValue="month"
          :max="max"
          :min="min"
          :disabled="disabled"
          @update:modelValue="setMonth"
        />
        <Years
          v-if="panelMode === PanelMode.YearView"
          :modelValue="month"
          :max="max"
          :min="min"
          :disabled="disabled"
          @update:modelValue="setYear"
        />
      </Box>
    </Box>
    <slot name="bottom">
      <Box flex direction="row" justify="between" alignItems="center">
        <Button
          v-if="!hideToday"
          size="md"
          variant="outlined"
          :disabled="disabled"
          @click="setToday"
        >
          Today
        </Button>
        <Typography v-if="periodDaysCount" variant="body2" color="textSecondary">
          {{ periodDaysCount }}
        </Typography>
      </Box>
    </slot>
  </Box>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { TimeZone } from '@lasso/shared/consts'

import Box from '../../Box/Box.vue'
import Button from '../../Button/Button.vue'
import ButtonIcon from '../../ButtonIcon/ButtonIcon.vue'
import Typography from '../../Typography/Typography.vue'
import Link from '../../Link/Link.vue'

import type { DateFormatTypes, DatePickerMode, DisabledPeriod, MultipleDatesValue, PeriodDateValue, PeriodPosition, SingleDateValue } from '../types'

import { PanelMode } from '../types'

import Days from './Days/Days.vue'
import Months from './Months/Months.vue'
import Years from './Years/Years.vue'
import Weekdays from './Weekdays/Weekdays.vue'

const props = withDefaults(
  defineProps<{
    month: DateTime
    min?: DateTime
    max?: DateTime
    disabled?: boolean
    disabledPeriods?: DisabledPeriod[]
    disabledWeekdays?: number[]
    dateFormat?: DateFormatTypes
    locale: string
    monthFormat?: string
    yearFormat?: string
    noPadding?: boolean
    hideForward?: boolean
    hideBack?: boolean
    hideToday?: boolean
    timezone: TimeZone
    datePickerMode: DatePickerMode
    singleDate?: SingleDateValue
    periodDates?: PeriodDateValue
    multipleDates?: MultipleDatesValue
    periodPosition?: PeriodPosition
    firstDayOfTheWeek?: number
  }>(),
  {
    dateFormat: 'MM/dd/yyyy',
    disabledPeriods: () => [],
    disabledWeekdays: () => [],
    monthFormat: 'MMMM',
    yearFormat: 'yyyy',
    noPadding: false,
    hideForward: false,
    hideBack: false,
    hideToday: false,
  },
)

const emits = defineEmits<{
  navigateMonth: [offset: number]
  setYear: [year: DateTime]
  setMonth: [month: DateTime]
  'update:singleDate': [date: SingleDateValue]
  'update:periodDates': [dates: PeriodDateValue]
  'update:multipleDates': [dates: MultipleDatesValue]
}>()

const panelMode = ref<PanelMode>(PanelMode.DayView)

const defaultPanelMode = computed(() => {
  switch (props.dateFormat) {
    case 'MM/yyyy':
    case 'MM/yy':
      return PanelMode.MonthView
    case 'MM/dd/yyyy':
    default:
      return PanelMode.DayView
  }
})

watch(() => defaultPanelMode.value, (newValue) => {
  panelMode.value = newValue
}, { immediate: true })

const periodDaysCount = computed(() => {
  if (props.datePickerMode === 'period' && props.dateFormat === 'MM/dd/yyyy' && props.periodDates?.start && props.periodDates?.end) {
    const start = props.periodDates?.start.startOf('day')
    const end = props.periodDates?.end.startOf('day')

    const diff = end.diff(start, 'days').days
    const days = Math.abs(diff) + 1

    return `${days} day${days > 1 ? 's' : ''} selected`
  }

  return ''
})

const panelPadding = computed(() => {
  return props.noPadding ? '0' : '4'
})

const navigateMonth = (offset: number) => {
  emits('navigateMonth', offset)
}

const setYear = (year: DateTime) => {
  emits('setYear', year)
  panelMode.value = PanelMode.MonthView
}

const setMonth = (month: DateTime) => {
  emits('setMonth', month)
  panelMode.value = defaultPanelMode.value
}

const setToday = () => {
  const today = DateTime.local().setLocale(props.locale).setZone(props.timezone).startOf('day')

  emits('setMonth', today.startOf('month'))

  if (props.datePickerMode === 'single') {
    emits('update:singleDate', today)
  }
  else if (props.datePickerMode === 'period') {
    if (props.periodPosition === 'start') {
      emits('update:periodDates', { start: today, end: props.periodDates?.end ?? null })
    }
    else {
      emits('update:periodDates', { start: props.periodDates?.start ?? null, end: today })
    }
  }
  else if (props.datePickerMode === 'multiple' && props.multipleDates) {
    const hasToday = props.multipleDates.some(date => date.hasSame(today, 'day'))

    if (!hasToday) {
      emits('update:multipleDates', [...props.multipleDates, today] as MultipleDatesValue)
    }
  }

  panelMode.value = defaultPanelMode.value
}

const handleSingleDateUpdate = (date: SingleDateValue) => {
  emits('update:singleDate', date)
}

const handlePeriodDatesUpdate = (dates: PeriodDateValue) => {
  emits('update:periodDates', dates)
}

const handleMultipleDatesUpdate = (dates: MultipleDatesValue) => {
  emits('update:multipleDates', dates)
}

const switchToMonthView = () => {
  panelMode.value = PanelMode.MonthView
}

const switchToYearView = () => {
  panelMode.value = PanelMode.YearView
}
</script>
