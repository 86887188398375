<template>
  <td
    class="table-cell"
    :class="classes"
    :style="styles"
    @click="onClick()"
  >
    <Box class="table-cell-inner" :class="{ loading }" flex row alignItems="center" :style="innerStyles">
      <Typography
        v-if="countValue && cellIndex === 0"
        :width="`${String(countValue).length * 10}px`"
        variant="body2" class="mr-2"
        alignText="center"
      >
        <Skeleton v-if="loading" />
        <template v-else>
          {{ countValue }}
        </template>
      </Typography>
      <Box v-if="checkbox && cellIndex === 0 && !loading" flex justify="center" mr="2" @click.stop>
        <Checkbox :modelValue="selected" :disabled="checkboxDisabled" @change="emit('select')" />
      </Box>

      <Skeleton v-if="loading" class="table-cell-skeleton" v-bind="skeletonStyle" />

      <Box v-if="$slots[id]" ref="contentRef" class="table-cell-content table-cell-content-box">
        <slot :name="id" v-bind="bindings" />
      </Box>

      <Typography v-else ref="contentRef" class="table-cell-content" v-bind="typographyProps">
        {{ value }}
      </Typography>
    </Box>
  </td>
</template>

<script setup lang="ts" generic="T extends Record<string, unknown>">
import { ComponentProps, KeyOf } from '@lasso/shared/types'
import { computed, nextTick, ref } from 'vue'
import { unrefElement } from '@vueuse/core'

import Typography from '../Typography/Typography.vue'
import Skeleton from '../Skeleton/Skeleton.vue'
import Box from '../Box/Box.vue'
import Checkbox from '../Checkbox/Checkbox.vue'

import { TableBaseLayout, TableBaseVariant } from '../TableBase'

import { TableColumn, TableSkeleton } from './types'
import { getTableCellStyles } from './utils'

const props = defineProps<{
  row: Partial<T>
  rowIndex: number
  column: TableColumn<T>
  cellIndex: number
  sticky: boolean
  stickyPosition: number
  hovered: boolean
  loading: boolean
  active: boolean
  checkbox: boolean
  checkboxDisabled: boolean
  selected: boolean
  align: string | number
  skeleton: TableSkeleton
  isTotal: boolean
  variant: TableBaseVariant
  isFirstInRow: boolean
  isLastInRow: boolean
  countValue?: number
  tableLayout: TableBaseLayout
}>()

const emit = defineEmits<{
  click: []
  select: []
}>()

const value = computed(() => {
  const rawValue = props.row[props.column.id]
  if (rawValue === undefined) {
    return ''
  }

  if (!props.column.modifier) {
    return String(rawValue)
  }

  return props.column.modifier(
    rawValue as Exclude<T[KeyOf<T>], undefined>,
    props.row as T,
    props.isTotal,
  )
})

const id = computed(() => props.column.id)

const cellStyles = computed(() => getTableCellStyles(props.column, props.tableLayout))

const classes = computed(() => ({
  [props.align]: true,
  'sticky z-10': props.sticky,
  'variant-leaderboard': props.variant === 'leaderboard',
  'variant-default': props.variant === 'default',
  'variant-dense': props.variant === 'dense',
  'first-in-row': props.isFirstInRow,
  'last-in-row': props.isLastInRow,
}))

const styles = computed(() => ({
  left: props.sticky ? `${props.stickyPosition}px` : undefined,
  ...(props.tableLayout === 'auto' ? cellStyles.value : {}),
}))

const innerStyles = computed(() => ({
  ...(props.tableLayout !== 'auto' ? cellStyles.value : {}),
}))

const typographyProps = computed((): Partial<ComponentProps<typeof Typography>> => ({
  variant: 'body2',
  color: 'textPrimary',
  whiteSpace: props.column.whiteSpace || 'nowrap',
  truncate: Boolean(props.column.maxWidth),
}))

const bindings = computed(() => ({
  row: props.row as T,
  rowIndex: props.rowIndex,
  column: props.column,
  cellIndex: props.cellIndex,
  value: value.value,
  rawValue: props.row[props.column.id] as Exclude<T[KeyOf<T>], undefined>,
  hovered: props.hovered,
  active: props.active,
}))

const skeletonStyle = computed(() => {
  const height = (props.skeleton?.height ?? 4) * 0.25

  return {
    height: `${height}rem`,
    width: 'auto',
  }
})

const contentRef = ref<HTMLTableCellElement>()

const onClick = () => {
  if (!props.column.clickable) {
    return
  }

  emit('click')

  nextTick(() => {
    unrefElement(contentRef)?.querySelector('input')?.focus()
  })
}
</script>

<style scoped>
.table-cell {
  @apply p-0
}

.variant-default.table-cell,
.variant-dense.table-cell {
  @apply
  rounded-none
  h-[54px]; /*height for td works like min-height - table cells will grow when the content does not fit.*/
}

.variant-leaderboard.table-cell {
  @apply
  py-1
  h-[60px]
}

.variant-leaderboard .table-cell-inner {
  @apply
  border-t border-b h-full border-gray-200;
}

.variant-leaderboard.first-in-row .table-cell-inner {
  @apply
  border-l rounded-tl-xl rounded-bl-xl;
}

.variant-leaderboard.last-in-row .table-cell-inner {
  @apply
  border-r rounded-tr-xl rounded-br-xl;
}

.table-cell-inner {
  @apply w-full px-4 py-1 text-14 tracking-15 relative
}

.table-cell-skeleton {
  @apply absolute left-4 right-4;
}

.table-cell-content-box {
  min-width: 0;
  width: 100%;
}

.table-cell-inner.loading {
  pointer-events: none;
}

.table-cell-inner.loading .table-cell-content {
  visibility: hidden;
}
</style>
