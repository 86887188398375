<template>
  <Box flex col justify="end" alignItems="center" grow="grow" component="footer">
    <Box flex justify="center" my="4">
      <Typography variant="caption1" color="textSecondary">
        &copy;{{ year }} <Link color="secondary" target="_blank" to="https://www.iqviadigital.com/">
          Media OS&#8482;
        </Link>
        Version: {{ version }} ({{ env }}).

        <template v-if="buildDate">
          {{ buildDate }}.
        </template>
      </Typography>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { formatDate } from '@lasso/shared/utils'

import Typography from '../Typography/Typography.vue'
import Box from '../Box/Box.vue'
import Link from '../Link/Link.vue'

const { buildTimeStamp } = defineProps<{
  buildTimeStamp?: number
}>()

const env = process.env.PUBLIC_DEPLOYMENT_ENVIRONMENT
const year = new Date().getFullYear()
const version = process.env.PUBLIC_DEPLOYMENT_VERSION

const buildDate = computed(() => {
  const timestamp = buildTimeStamp ?? Number(process.env.PUBLIC_DEPLOYMENT_DATE || 0) * 1000
  return timestamp ? formatDate(new Date(timestamp), 'longDateTimeAbbrSecTimeZone', null, false) : ''
})
</script>
