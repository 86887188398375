<template>
  <Box class="select-panel-item" :class="{ expandable: isExpandable() }" data-test-id="select-panel-item">
    <Box
      :component="isExpandable() ? 'button' : 'label'"
      :type="isExpandable() ? 'button' : ''"
      class="select-panel-item-inner"
      @click="isExpandable() ? toggleExpanded() : () => {}"
    >
      <slot name="control" />
      <Box class="select-panel-item-content" data-test-id="select-panel-item-content">
        <slot name="content">
          <slot name="chip">
            <template v-if="chips.length > 0">
              <Box
                v-for="_chip in chips"
                :key="_chip"
                data-test-id="select-panel-item-chip"
                class="flex-shrink-0"
                minWidth="10px"
                flex
              >
                <Chip variant="outlined" size="2xs" :label="_chip" />
              </Box>
            </template>
          </slot>
          <Tooltip class="flex min-w-0 gap-2" placement="top">
            <Typography
              ref="labelRef"
              variant="body2"
              display="block"
              class="select-panel-item-label"
              :class="{ singleLine }"
              wordBreak="anywhere"
            >
              <slot name="label">
                <Highlight :highlight="searchQuery" :label="label" />
              </slot>&nbsp;<Tooltip
                v-if="info && !singleLine"
                data-test-id="select-item-panel-info"
                :content="info"
                placement="top"
                withIcon
                inline
                class="align-middle"
                :class="{ 'cursor-pointer': onClickInfo }"
                @click="onInfoClick($event)"
              />
            </Typography>
            <template v-if="overflown && singleLine" #content>
              {{ label }}
            </template>
          </Tooltip>
          <Tooltip
            v-if="info && singleLine"
            data-test-id="select-item-panel-info"
            :content="info"
            placement="top"
            withIcon
            inline
            class="align-middle"
            :class="{ 'cursor-pointer': onClickInfo }"
            @click="onInfoClick($event)"
          />
        </slot>
        <Box v-if="slots.extra" class="select-panel-extra">
          <Box class="select-panel-extra-inner" @click.stop>
            <slot name="extra" />
          </Box>
        </Box>
      </Box>
      <Icon
        v-if="isExpandable()"
        :icon="expanded ? 'remove' : 'add'"
        variant="outlined"
      />
    </Box>
    <Transitions name="slide">
      <Box v-if="expanded">
        <Box class="select-panel-item-children">
          <slot v-bind="{ nextLevel: level + 1 }" />
        </Box>
      </Box>
    </Transitions>
  </Box>
</template>

<script setup lang="ts" generic="T extends boolean | any[]">
import { useToggle } from '@vueuse/core'
import { ComponentPublicInstance, computed, ref } from 'vue'

import { arrayify } from '@lasso/shared/utils'
import { useElementOverflown } from '@lasso/shared/hooks'

import Box from '../Box/Box.vue'
import Chip from '../Chip/Chip.vue'
import Typography from '../Typography/Typography.vue'
import Highlight from '../Highlight/Highlight.vue'
import Icon from '../Icon/Icon.vue'
import Transitions from '../Transitions/Transitions.vue'
import Tooltip from '../Tooltip/Tooltip.vue'

const {
  chip = '',
  searchQuery = '',
  label = '',
  info = '',
  expandable = false,
  level = 0,
  singleLine = false,
  onClickInfo,
} = defineProps<{
  searchQuery?: string
  chip?: string | number | Array<string | number>
  label?: string
  info?: string
  expandable?: boolean
  level?: number
  singleLine?: boolean
  onClickInfo?: Function
}>()

const emit = defineEmits<{
  'clickInfo': []
}>()

const slots = defineSlots<{
  control?: () => any
  default?: (props: { nextLevel: number }) => any
  content?: () => any
  chip?: () => any
  label?: () => any
  extra?: () => any
}>()

const expanded = defineModel<boolean>('expanded', { default: false })
const toggleExpanded = useToggle(expanded)

const chips = computed(() =>
  arrayify(chip).filter(chip => chip !== ''),
)

const labelRef = ref<ComponentPublicInstance>()
const { overflown } = useElementOverflown(labelRef)

const isExpandable = () => expandable || Boolean(slots.default)

const onInfoClick = (event: Event) => {
  if (onClickInfo) {
    event.stopPropagation()
    event.preventDefault()
  }

  emit('clickInfo')
}
</script>

<style scoped>
.select-panel-item {
  @apply border-b border-base-500 last-of-type:border-b-0;
}

.select-panel-item-inner {
  @apply cursor-pointer flex items-center gap-2 grow flex-row px-4 py-1 min-h-[34px] w-full;
  padding-left: calc(16px * calc(v-bind(level) + 1));
}

.select-panel-item-content {
  @apply flex items-center gap-2 grow min-w-0;
}

.select-panel-item-children {
  @apply border-t border-base-500;
}

.select-panel-item-label {
  @apply basis-0 grow;
}

.select-panel-item-label.singleLine {
  @apply whitespace-nowrap truncate;
}

.select-panel-extra {
  @apply flex flex-grow justify-end;
}

.select-panel-item:not(.expandable) .select-panel-extra {
  @apply pr-6;
}

.select-panel-extra-inner {
  @apply flex gap-0.5;
}
</style>
