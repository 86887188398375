<template>
  <InputText
    ref="inputRef"
    :key="renderKey"
    :modelValue="inputDateInternal"
    class="input-date"
    :width="inputWidth"
    icon="date_range"
    :placeholder="placeHolderValue"
    :mask="dateMask"
    :disabled="disabled"
    :dataTestId="dataTestId"
    @update:modelValue="onInputChange"
    @focus="handleFocus"
    @blur="handleBlur"
  />
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, nextTick, ref, watch } from 'vue'
import IMask from 'imask'
import { DateTime } from 'luxon'
import { TimeZone } from '@lasso/shared/consts'

import { InputText } from '../../Input'
import { DateFormatTypes, DisabledPeriod } from '../../DatePicker/types'

const props = withDefaults(defineProps<{
  dataTestId?: string
  modelValue?: string
  dateFormat: DateFormatTypes
  placeholder?: string
  disabled?: boolean
  min?: DateTime
  max?: DateTime
  timezone: TimeZone
  locale: string
  disabledPeriods?: DisabledPeriod[]
}>(), {
  placeholder: '',
  dataTestId: '',
  modelValue: '',
  disabledPeriods: () => [],
})

const emits = defineEmits<{
  'update:modelValue': [string]
}>()

const inputDateInternal = ref<string | undefined>(props.modelValue)
const inputRef = ref<ComponentPublicInstance >()
const renderKey = ref(0)

/**
 * Triggers on transition from null to a valid date or from a valid date to null
 * Example (the dateFormat value is MM/dd/yyyy):
 * - from '' input value to '11/25/2024' -> triggers; the value passed to the function is '11/25/2024'
 * - from '11/25/2024' input value to '' -> triggers; the value passed to the function is null
 * - from '' input value to '' -> does NOT trigger; the value is not changing
 * - from '11/25/2024' input value to '11/25/2024' -> does NOT trigger; the value is not changing
 * - from '11/25/2024' input value to '11/25/2' -> does NOT trigger; the date is not satisfies the mask
 * - from '11/25/2024' input value to '11/25/2025555' -> does NOT trigger; the date is satisfies the mask but has extra characters
 */
const onInputChange = (value: string | null) => {
  if (!value && !inputRef.value?.$el?.querySelector('input')?.value) {
    inputDateInternal.value = value ?? ''
    emits('update:modelValue', value ?? '')
    return
  }

  const newDate = DateTime.fromFormat(value ?? '', props.dateFormat, {
    zone: props.timezone,
    locale: props.locale,
  })

  let isValid = newDate.isValid

  if (!isValid) {
    return
  }

  // Check if date is in range
  if ((props.min && newDate < props.min) || (props.max && newDate > props.max)) {
    isValid = false
  }

  // Check if date is out of disabled periods
  if (isValid && props.disabledPeriods?.length) {
    const normalizedDate = newDate.startOf('day')
    for (const period of props.disabledPeriods) {
      const start = period.start.startOf('day')
      const end = period.end.endOf('day')
      if (normalizedDate >= start && normalizedDate <= end) {
        isValid = false
        break
      }
    }
  }

  // If date is valid, update the input value and emit it
  if (isValid) {
    const finalValue = newDate.toFormat(props.dateFormat)
    inputDateInternal.value = finalValue
    emits('update:modelValue', finalValue)
  }
  // Otherwise do not emit the value; If the value stays invalid on blur,
  // the input be rerendered and previous valid value will be shown;
}

const handleBlur = () => {
  const inputElement = inputRef.value?.$el.querySelector('input')

  if (!inputElement) {
    return
  }

  if (inputElement.value !== '' && props.modelValue !== '' && inputElement.value !== props.modelValue) {
    inputElement.value = props.modelValue
    inputDateInternal.value = props.modelValue
  }

  nextTick(() => {
    if (props.modelValue === '' && inputElement.value === '') {
      inputDateInternal.value = ''
      emits('update:modelValue', '')
    }
  })

  // Bad but at least functional workaround to visually reset an invalid value
  renderKey.value++
}

watch(() => props.modelValue, (newValue) => {
  if (newValue !== inputDateInternal.value) {
    inputDateInternal.value = newValue
  }
})

const dateMask = computed(() => ({
  mask: Date,
  pattern: props.dateFormat,
  blocks: {
    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      maxLength: 2,
    },
    dd: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      maxLength: 2,
    },
    yy: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 99,
      maxLength: 2,
    },
    yyyy: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 2999,
      maxLength: 4,
    },
  },
  parse(str: string) {
    return str
  },
  format(date: string) {
    return date
  },
  autofix: true,
  lazy: true,
  overwrite: true,
}))

const inputWidth = computed(() => {
  switch (props.dateFormat) {
    case 'MM/yy':
      return '96px'
    case 'MM/yyyy':
      return '112px'
    case 'MM/dd/yyyy':
    default:
      return '133px'
  }
})

const placeHolderValue = computed(() => {
  if (props.placeholder) {
    return props.placeholder
  }
  else {
    return props.dateFormat.toUpperCase()
  }
})

const handleFocus = (event: FocusEvent) => {
  const input = event.target as HTMLInputElement
  input.select()
}
</script>

<style scoped src="./inputdatefield.styles.css" />
