<template>
  <LayoutLanding component="form" v-bind="props">
    <template v-if="$slots.title" #title>
      <slot name="title" />
    </template>

    <template v-if="$slots.titlePlaceholder" #titlePlaceholder>
      <slot name="titlePlaceholder" />
    </template>

    <template v-if="$slots.info" #info>
      <slot name="info" />
    </template>

    <template v-if="$slots.summary" #summary>
      <slot name="summary" />
    </template>

    <template v-if="$slots.breadcrumbs" #breadcrumbs>
      <slot name="breadcrumbs" />
    </template>

    <template v-if="$slots.tabs" #tabs>
      <slot name="tabs" />
    </template>

    <slot />

    <Box v-if="$slots.actionsStart || $slots.actions" flex space="2" wrap="wrap" justify="between">
      <Box flex space="2" wrap="wrap">
        <slot name="actionsStart" />
      </Box>
      <Box flex space="2" wrap="wrap">
        <slot name="actions" />
      </Box>
    </Box>

    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </LayoutLanding>
</template>

<script setup lang="ts">
import { Box } from '../../Box'
import { LayoutLanding } from '../LayoutLanding'

const props = defineProps<{
  stickyHeader?: boolean
  noFooter?: boolean
  actionsKey?: PropertyKey | null
  summaryWidth?: string
}>()
</script>
