import { DateTime } from 'luxon'

export type DateFormatTypes = 'MM/dd/yyyy' | 'MM/yy' | 'MM/yyyy' | 'MM/dd/yy'

export type DisabledPeriod = {
  start: DateTime
  end: DateTime
}

export type PeriodPosition = 'start' | 'end'

export type DatePickerMode = 'single' | 'multiple' | 'period'

export type SingleDateValue = DateTime | null

export type PeriodDateValue = {
  start: DateTime | null
  end: DateTime | null
}

export type MultipleDatesValue = DateTime[]

export type DatePickerValue = {
  single: SingleDateValue
  period: PeriodDateValue
  multiple: MultipleDatesValue
}

export type DatePickerValueByMode<T extends DatePickerMode> = DatePickerValue[T]

export enum PanelMode {
  MonthView = 'month',
  YearView = 'year',
  DayView = 'day',
}

export enum MonthRelation {
  PREVIOUS_MONTH = 'previous',
  CURRENT_MONTH = 'current',
  NEXT_MONTH = 'next',
}

export type CalendarDay = {
  date: DateTime
  monthRelation: MonthRelation
  disabled?: boolean
}
