<template>
  <InputText
    :key="renderKey"
    :modelValue="time"
    :disabled="disabled"
    type="time"
    width="133px"
    icon="schedule"
    class="input-time"
    pattern="[0-9]{2}:[0-9]{2}"
    :dataTestId="dataTestId"
    @update:modelValue="onInput"
    @blur="onBlur"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useVModel } from '@vueuse/core'

import { InputText } from '../../Input'

const props = withDefaults(defineProps<{
  dataTestId?: string
  modelValue: { hour: string; minute: string }
  disabled?: boolean
}>(), {
  dataTestId: '',
})

const emits = defineEmits<{
  'update:modelValue': [{ hour: string; minute: string }]
}>()

const timeInternal = useVModel(props, 'modelValue', emits)
const renderKey = ref(0)

const time = computed(() => (timeInternal.value.hour && timeInternal.value.minute)
  ? `${timeInternal.value.hour.padStart(2, '0')}:${timeInternal.value.minute.padStart(2, '0')}`
  : '',
)

const onInput = (value: string) => {
  const [hour = '', minute = ''] = value.split(':')
  timeInternal.value = { hour, minute }
}

// Bad but at least functional workaround to visually reset an uncompleted edit
const onBlur = () => {
  renderKey.value++
}
</script>

<style scoped src="./inputtimefield.styles.css" />
