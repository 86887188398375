<template>
  <Grid container xs="7">
    <Grid v-for="({ dayName, isoDayNumber }, index) in weekDays" :key="index" item justifySelf="center" :data-test-id="`iso-weekday-${isoDayNumber}`">
      <Typography color="textSecondary" variant="caption1">
        {{ dayName }}
      </Typography>
    </Grid>
  </Grid>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Info } from 'luxon'

import Grid from '../../../Grid/Grid.vue'
import Typography from '../../../Typography/Typography.vue'

const props = defineProps<{
  locale: string
  firstDayOfTheWeek?: number
}>()

const weekDays = computed(() => {
  // The number of the first day of the week is 1-7, where 1 is Monday and 7 is Sunday.
  const startOfWeek = props.firstDayOfTheWeek
    ? props.firstDayOfTheWeek
    : Info.getStartOfWeek({ locale: props.locale })

  // Info.weekdays returns a weekdays array starting from Monday.
  const weekdays = Info.weekdays('short', { locale: props.locale })
    .map((day, index) => ({
      dayName: day,
      isoDayNumber: index + 1,
    }))

  // Rearrange the weekdays array so that it starts from the locale-specific first day of the week.
  const before = weekdays.slice(startOfWeek - 1)
  const after = weekdays.slice(0, startOfWeek - 1)
  return [...before, ...after]
})
</script>
