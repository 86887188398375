<template>
  <RecycleScroller
    ref="scrollerRef"
    :items="items"
    :direction="direction"
    :itemSize="itemSize"
    :gridItems="gridItems"
    :itemSecondarySize="itemSecondarySize"
    :minItemSize="minItemSize"
    :sizeField="sizeField"
    :typeField="typeField"
    :keyField="keyField"
    :pageMode="pageMode"
    :prerender="prerender"
    :buffer="buffer"
    :emitUpdate="emitUpdate"
    :updateInterval="updateInterval"
    :listClass="listClass"
    :itemClass="itemClass"
    :listTag="listTag"
    :itemTag="itemTag"
  >
    <template v-if="slots.before" #before>
      <slot name="before" />
    </template>
    <template v-if="slots.default" #default="slotProps">
      <slot v-if="typeof slotProps.index === 'number'" v-bind="slotProps" />
    </template>
    <template v-if="slots.after" #after>
      <slot name="after" />
    </template>
  </RecycleScroller>
</template>

<script setup lang="ts" generic="T">
/* eslint-disable vue/enforce-style-attribute */
import { RecycleScroller } from 'vue-virtual-scroller'
import { KeyOf } from '@lasso/shared/types'
import { ref } from 'vue'

withDefaults(defineProps<{
  items: T[]
  direction?: 'vertical' | 'horizontal'
  itemSize?: number | null
  gridItems?: number | string | null
  itemSecondarySize?: number
  minItemSize?: number | string | null
  sizeField?: string
  typeField?: string
  keyField: KeyOf<T>
  pageMode?: boolean
  prerender?: number
  buffer?: number
  emitUpdate?: boolean
  updateInterval?: number
  listClass?: any
  itemClass?: any
  listTag?: string
  itemTag?: string
}>(), {
  direction: 'vertical',
  itemSize: 34,
  gridItems: null,
  minItemSize: null,
  sizeField: 'size',
  typeField: 'type',
  pageMode: false,
  prerender: 0,
  buffer: 200,
  emitUpdate: false,
  updateInterval: 0,
  listClass: '',
  itemClass: '',
  listTag: 'div',
  itemTag: 'div',
})

const slots = defineSlots<{
  default?: (props: { item: T; index: number; active: boolean }) => any
  before?: () => any
  after?: () => any
}>()

type Exposed = {
  scrollToItem: (index: number) => void
  scrollToPosition: (position: number) => void
}

const scrollerRef = ref<Exposed>()

defineExpose<Exposed>({
  scrollToItem: index => scrollerRef.value?.scrollToItem(index),
  scrollToPosition: position => scrollerRef.value?.scrollToPosition(position),
})
</script>

<style src="vue-virtual-scroller/dist/vue-virtual-scroller.css" />
