<template>
  <Box
    data-test-id="form-field"
    :data-test-for="id"
    flex
    :direction="direction"
    :class="formControlClasses"
    v-bind="$attrs"
  >
    <FormFieldLabel
      v-if="!hideLabel && label"
      component="label"
      :for="id"
      :hide="hideLabel || !label"
      :required="required"
      variant="form-label"
      :color="labelColor"
    >
      <Box component="span" flex justify="center" alignItems="center" spaceX="0.5">
        <Typography variant="inherit">
          {{ label }}
        </Typography>
        <Tooltip
          v-if="$slots.tooltip || tooltip"
          placement="top"
          offsetDistance="8"
          v-bind="tooltipProps"
        >
          <template #default>
            <Icon
              icon="info"
              size="xs"
              variant="outlined"
              color="inherit"
            />
          </template>
          <template #content>
            <slot name="tooltip">
              {{ tooltip }}
            </slot>
          </template>
        </Tooltip>
        <Box v-if="$slots['custom-action']">
          <slot
            name="custom-action"
          />
        </Box>
      </Box>
    </FormFieldLabel>

    <Box flex>
      <slot v-bind="{ id, disabled, error: hasError }" />
    </Box>

    <Transitions>
      <InputHelperText
        v-if="inputHelperText"
        :data-test-id="hasError ? 'form-error' : undefined"
        :variant="inputHelperVariant"
        pt="1"
      >
        {{ inputHelperText }}
      </InputHelperText>
    </Transitions>
  </Box>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue'
import { computed, provide, toRef, useSlots } from 'vue'
import { useField } from 'vee-validate'

import { useFormEvents } from '@lasso/shared/hooks'

import Box from '../Box/Box.vue'

import InputHelperText from '../Input/InputHelperText.vue'
import Transitions from '../Transitions/Transitions.vue'
import FormFieldLabel from '../FormFieldLabel/FormFieldLabel.vue'
import Typography from '../Typography/Typography.vue'
import Tooltip from '../Tooltip/Tooltip.vue'
import Icon from '../Icon/Icon.vue'

import { FormFieldProps } from './types'

const props = withDefaults(defineProps<FormFieldProps>(), {
  id: '',
  fullWidth: true,
  direction: 'col',
  label: '',
  hideLabel: false,
  helperText: '',
  required: false,
  disabled: false,
  errorText: '',
  keepValueOnUnmount: true,
})

const slots = useSlots()

const formControlClasses = computed(() => {
  const gapProp = props.direction === 'col' ? 'gap-y-1.5' : 'gap-x-1.5'

  return {
    'w-full': props.fullWidth,
    [gapProp]: !!slots.default,
  }
})

const id = toRef(props, 'id') as Ref<string>
const vee = props.id
  ? useField(id, undefined, {
    // @ts-expect-error TODO: rewrite form components
      type: 'input',
      validateOnValueUpdate: false,
      keepValueOnUnmount: props.keepValueOnUnmount,
    })
  : null

const errorMessage = computed(() => vee?.errorMessage.value || props.errorText || '')
const hasError = computed(() => !!errorMessage.value)
const inputHelperText = computed(() => errorMessage.value || props.helperText || '')
const inputHelperVariant = computed(() => (hasError.value ? 'error' : 'default'))

const formEvents = useFormEvents()

formEvents.validationError.on(() => {
  if (hasError.value) {
    formEvents.fieldError.trigger()
  }
})

provide(
  'formControlHasError',
  computed(() => hasError.value),
)

const labelColor = computed(() => {
  if (props.disabled) {
    return 'disabled'
  }

  return 'textSecondaryLight'
})

defineExpose({
  hasError,
})
</script>
