import { arrayIncludes, arrayify } from '@lasso/shared/utils'

import { KeyOf } from '@lasso/shared/types'

import { CSSProperties, toValue } from 'vue'

import { TableBaseLayout } from '../TableBase'

import { TableColumn, TableColumns } from './types'

export const filterTableFields = <T extends Record<string, unknown>, K extends KeyOf<T>>(
  fields: TableColumns<T>,
  key: K | K[],
) => {
  const keys = arrayify(key)

  return fields.filter(field => !arrayIncludes(keys, field.id)) as unknown as TableColumns<Omit<T, K>>
}

export const getTableCellStyles = (column: TableColumn<any>, layout: TableBaseLayout): CSSProperties => {
  const { grow, width, maxWidth, minWidth } = toValue(column)

  if (toValue(layout) === 'auto') {
    return {
      width: width ?? (grow ? '' : '1px'),
      maxWidth: maxWidth ?? (grow ? '400px' : ''),
      minWidth,
    }
  }
  else {
    return {
      width,
      maxWidth: maxWidth || width,
      minWidth: minWidth || width,
    }
  }
}
