import type { PaperColor, PaperRounded, PaperShadow, PaperVariant } from './types'

export const variant: Record<PaperVariant, string | undefined> = {
  contained: '',
  filled: 'paper-filled',
  outlined: 'paper-outlined',
}

export const color: Record<PaperColor, string> = {
  'base': 'bg-base-100',
  'base-200': 'bg-base-200',
  'secondary-light': 'bg-secondary-light',
  'primary': 'bg-primary',
  'primary-light': 'bg-primary-light',
}

export const rounded: Record<PaperRounded, string> = {
  rounded: 'rounded',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  none: 'rounded-none',
}

export const shadow: Record<PaperShadow, string> = {
  lg: 'shadow-lg',
  none: 'shadow-none',
}
