<template>
  <Box flex col data-test-id="select-panel-item-group">
    <Box width="100%" flex spaceX="2" alignItems="center">
      <Box component="button" type="button" flex spaceX="1" alignItems="center" grow="grow" @click="toggleModelValue()">
        <Typography variant="overline1" uppercase>
          <slot name="title">
            {{ title }}
          </slot>
        </Typography>
        <Icon :icon="modelValueInternal ? 'keyboard_arrow_down' : 'keyboard_arrow_up'" variant="outlined" />
      </Box>
      <Box flex spaceX="2" alignItems="center">
        <slot name="action" />
      </Box>
    </Box>

    <Transitions name="slide">
      <Box v-if="modelValueInternal">
        <Box flex col pt="2" spaceY="2">
          <slot />
        </Box>
      </Box>
    </Transitions>
  </Box>
</template>

<script setup lang="ts">
import { useToggle, useVModel } from '@vueuse/core'

import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import Typography from '../Typography/Typography.vue'
import Transitions from '../Transitions/Transitions.vue'

const props = withDefaults(defineProps<{
  modelValue?: boolean
  title?: string
}>(), {
  modelValue: true,
  title: '',
})

const emit = defineEmits<{
  'update:modelValue': [boolean]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit, { passive: true })
const toggleModelValue = useToggle(modelValueInternal)
</script>
