<template>
  <Box class="overflow-x-auto rounded" width="100%" @click="$emit('click')">
    <table class="table w-full" :class="tableClasses" :style="style">
      <thead v-if="$slots.header">
        <slot name="header">
          <th>
            <slot />
          </th>
        </slot>
      </thead>
      <tbody v-if="$slots.body">
        <slot name="body" />
      </tbody>
      <tfoot v-if="$slots.footer">
        <slot name="footer" />
      </tfoot>
    </table>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'

import { checkForUndefined } from '../../utils'

import type { TableBasePropTypes } from './types'

import { layouts, variants } from './classes'

const props = withDefaults(defineProps<TableBasePropTypes>(), {
  zebra: false,
  tableLayout: 'default',
})

const tableClasses = computed(() => {
  const variant = checkForUndefined(props.variant, variants)
  const layout = checkForUndefined(props.tableLayout, layouts)
  return {
    'table-zebra': props.zebra,
    [variant]: props.variant,
    [layout]: props.tableLayout,
  }
})

const style = computed(() => {
  return {
    minWidth: props.tableMinWidth,
  }
})
</script>
