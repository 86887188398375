<template>
  <Tippy
    ref="tippyRef"
    trigger="manual"
    :hideOnClick="false"
    px="0"
    py="0"
    @show="handlePickerShow"
    @hidden="handlePickerHide"
    @clickOutside="handleClickOutside"
  >
    <Box flex alignItems="center">
      <FormField :id="id" :required="required" :label="label" :errorText="error" width="auto">
        <InputDateField
          v-model="dateFieldValue"
          :class="{ 'left-input': displayTime }"
          :dateFormat="dateFormat"
          :disabled="disabled"
          :min="minDateInternal"
          :max="maxDateInternal"
          :disabledPeriods="disabledPeriods"
          :timezone="timezone"
          :locale="locale"
          autocomplete="off"
          @focus="handleInputFieldFocus"
          @blur="handleDateFieldBlur"
        />
        <InputTimeField
          v-if="displayTime"
          v-model="timeFieldValue"
          :class="{ 'right-input': displayTime }"
          :disabled="disabled || timeDisabled"
          autocomplete="off"
          @focus="handleInputFieldFocus"
          @blur="handleTimeFieldBlur"
        />
      </FormField>
    </Box>
    <template #content>
      <DatePicker
        v-if="isShown"
        v-bind="$attrs"
        v-model:singleDate="modelValueInternal"
        mode="single"
        :dateFormat="dateFormat"
        :disabled="disabled"
        :min="minDateInternal"
        :max="maxDateInternal"
        :timezone="timezone"
        :locale="locale"
        skipDevOnlyTimezoneCheck
        @mousedown.stop.prevent
      />
    </template>
  </Tippy>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, ref, watch } from 'vue'
import { DateTime } from 'luxon'
import { TimeZone } from '@lasso/shared/consts'
import { ComponentExposed } from '@lasso/shared/types'

import Box from '../Box/Box.vue'
import DatePicker from '../DatePicker/DatePicker.vue'
import FormField from '../FormField/FormField.vue'
import Tippy from '../Tippy/Tippy.vue'
import { DateFormatTypes } from '../DatePicker/types'

import { useTimezoneConsistencyCheck } from '../../hooks/useTimezoneConsistencyCheck'

import InputDateField from './InputDateField/InputDateField.vue'
import InputTimeField from './InputTimeField/InputTimeField.vue'
import { getHour, getMinute, normalizeTimezoneIfNeeded } from './utils'

const props = withDefaults(defineProps<{
  dataTestId?: string
  id?: string
  modelValue: DateTime | null
  displayTime?: boolean
  required?: boolean
  disabled?: boolean
  timeDisabled?: boolean
  dateFormat?: DateFormatTypes
  label?: string
  error?: string
  locale?: string
  min?: DateTime
  max?: DateTime
  disabledPeriods?: { start: DateTime; end: DateTime }[]
  timezone: TimeZone
}
>(), {
  displayTime: false,
  required: false,
  dateFormat: 'MM/dd/yyyy',
  locale: 'en-US',
  label: '',
  error: '',
})

const emits = defineEmits<{
  'update:modelValue': [value: DateTime | null]
  show: []
  hide: []
}>()

defineOptions({
  inheritAttrs: false,
})

const isShown = ref(false)
const tippyRef = ref<ComponentPublicInstance & ComponentExposed<typeof Tippy>>()

const timeInternal = ref<{ hour: string; minute: string }>({ hour: '', minute: '' })

const hasTimeValue = () => timeInternal.value.hour !== '' && timeInternal.value.minute !== ''

const cleanTimeInternal = () => {
  timeInternal.value = { hour: '', minute: '' }
}

// Preserve the time when the modelValue is set
watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    timeInternal.value = { hour: getHour(newValue), minute: getMinute(newValue) }
  }
  else {
    cleanTimeInternal()
  }
}, { immediate: true })

/*
 * The modelValueInternal normalizes the date from modelValue to the timezone provided in the props;
 * The further date manipulations within the component will be done in the timezone provided in the props;
 * Such normalized date will be emitted from the component only with the first update:modelValue event;
 */
const modelValueInternal = computed<DateTime | null>({
  get: () => {
    return props.modelValue && normalizeTimezoneIfNeeded(props.modelValue, props.timezone)
  },
  set: (newValue) => {
    if (!newValue) {
      emits('update:modelValue', null)
    }
    else if (hasTimeValue() && newValue.hour === 0 && newValue.minute === 0) {
      emits('update:modelValue', newValue.set({
        hour: parseInt(timeInternal.value.hour) || 0,
        minute: parseInt(timeInternal.value.minute) || 0,
        second: 0,
        millisecond: 0,
      }))
    }
    else {
      emits('update:modelValue', newValue)
    }
  },
})

const minDateInternal = computed(() => (props.min && normalizeTimezoneIfNeeded(props.min, props.timezone)))

const maxDateInternal = computed(() => (props.max && normalizeTimezoneIfNeeded(props.max, props.timezone)))

const dateFieldValue = computed({
  get: () => {
    return modelValueInternal.value?.toFormat(props.dateFormat)
  },
  set: (newDateString) => {
    if (!newDateString) {
      return
    }

    let newDate = DateTime.fromFormat(newDateString, props.dateFormat, {
      zone: props.timezone,
      locale: props.locale,
    })

    if (hasTimeValue()) {
      newDate = newDate.set({
        hour: parseInt(timeInternal.value.hour) || 0,
        minute: parseInt(timeInternal.value.minute) || 0,
        second: 0,
        millisecond: 0,
      })
    }
    else if (props.modelValue) {
      newDate = newDate.set({
        hour: props.modelValue.hour,
        minute: props.modelValue.minute,
        second: 0,
        millisecond: 0,
      })
    }

    modelValueInternal.value = newDate
  },
})

const timeFieldValue = computed({
  get: () => {
    // If there is a date, take the time from the date
    if (props.modelValue) {
      return { hour: getHour(props.modelValue), minute: getMinute(props.modelValue) }
    }
    // If there is no date, but there is saved time, return it
    else if (hasTimeValue()) {
      return {
        hour: timeInternal.value.hour,
        minute: timeInternal.value.minute,
      }
    }
    // Otherwise, return empty strings
    return { hour: '', minute: '' }
  },
  set: ({ hour, minute }) => {
    if (!hour || !minute) {
      return
    }

    timeInternal.value = { hour, minute }

    if (props.modelValue && DateTime.isDateTime(props.modelValue)) {
      if (modelValueInternal.value) {
        modelValueInternal.value = modelValueInternal.value.set({
          hour: parseInt(hour) || 0,
          minute: parseInt(minute) || 0,
          second: 0,
          millisecond: 0,
        })
      }
    }
  },
})

const handlePickerShow = () => {
  emits('show')
  isShown.value = true
}

const handlePickerHide = () => {
  emits('hide')
  isShown.value = false
}

const handleInputFieldFocus = () => tippyRef.value?.tippyInstance?.show()

const handleClickOutside = (instance: any) => {
  const target = instance?.reference
  const clickedElement = instance?.event?.target as HTMLElement

  if (target?.contains(clickedElement)) {
    return
  }

  tippyRef.value?.tippyInstance?.hide()
}

const handleDateFieldBlur = (blurEvent: FocusEvent) => {
  const inputValue = (blurEvent.target as HTMLInputElement).value
  const relatedTarget = blurEvent.relatedTarget as HTMLElement

  // If the focus moves to the time field or picker, do not reset the values and do not close the picker
  if (
    relatedTarget
    && (tippyRef.value?.$el.contains(relatedTarget))
  ) {
    return
  }

  // If the date field is empty or incomplete, reset the date
  if (inputValue === '' || inputValue.length < props.dateFormat.length) {
    modelValueInternal.value = null
    // Do not reset timeInternal to keep the entered time
  }

  // Close the picker only if the focus is not inside the component
  if (!tippyRef.value?.$el.contains(document.activeElement)) {
    tippyRef.value?.tippyInstance?.hide()

    // Only reset the time when completely exiting the component if the date is not selected
    if (!modelValueInternal.value) {
      cleanTimeInternal()
    }
  }
}

const handleTimeFieldBlur = (blurEvent: FocusEvent) => {
  const relatedTarget = blurEvent.relatedTarget as HTMLElement

  // If the focus moves to the date field or picker, do not reset the values and do not close the picker
  if (
    relatedTarget
    && (tippyRef.value?.$el.contains(relatedTarget))
  ) {
    return
  }

  // Close the picker only if the focus is not inside the component
  if (!tippyRef.value?.$el.contains(document.activeElement)) {
    tippyRef.value?.tippyInstance?.hide()

    // Only reset the time when completely exiting the component if the date is not selected
    if (!modelValueInternal.value) {
      cleanTimeInternal()
    }
  }
}

useTimezoneConsistencyCheck({
  timezone: () => props.timezone,
  datesToValidate: () => ({
    modelValue: props.modelValue,
    min: props.min,
    max: props.max,
  }),
})
</script>

<style scoped src="./inputdate.styles.css" />
