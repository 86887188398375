<template>
  <Box class="breadcrumbs">
    <ul class="breadcrumbs-list">
      <li
        v-for="(crumb, index) in crumbs"
        :key="crumb.path"
        :class="getStateClass(index)"
      >
        <RouterLink v-if="index + 1 < crumbs.length && crumb.path" :to="crumb.path">
          {{ crumb.label }}
        </RouterLink>
        <template v-else>
          {{ crumb.label }}
        </template>
      </li>
    </ul>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useRoute, useRouter } from 'vue-router'

import Box from '../Box/Box.vue'

import { BreadcrumbsCrumb, BreadcrumbsCrumbRecord } from './types'
import { useBreadcrumbs } from './useBreadcrumbs'

const { crumbs: crumbsBase } = defineProps<{
  crumbs?: Array<BreadcrumbsCrumbRecord>
}>()

const router = useRouter()
const route = useRoute()
const providedCrumbs = useBreadcrumbs()

const crumbs = computed(() => {
  return (crumbsBase ?? providedCrumbs.value).map((crumb): BreadcrumbsCrumb => {
    if (!('to' in crumb)) {
      return crumb
    }

    const to = crumb.to === 'current' ? route : router.resolve(crumb.to)
    const label = String(crumb.label ?? to.meta.title ?? to.name ?? to.path)

    return { label, path: to.path }
  })
})

const getStateClass = (index: number) => ({
  'breadcrumb-inactive': crumbs.value.length - 1 !== index,
  'breadcrumb-active': crumbs.value.length - 1 === index,
})
</script>

<style scoped>
.breadcrumbs {
  @apply
    py-0
    text-12
    overflow-hidden; /* Firefox fix to prevent unnecessary scrollbar */
}
.breadcrumbs-list {
  @apply flex-wrap;
}
.breadcrumb-active {
  @apply !text-textPrimary;
}
.breadcrumb-inactive {
  @apply text-textSecondaryLight;
}
</style>
